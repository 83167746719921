import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Dropdown, Form, Input, TextArea } from "semantic-ui-react";
import axios from "axios";
import imgResultados from "../../assets/dashboard/icono_vista_resultados.svg";
import GradientButton from "../../components/GradientButton";
import { useNavigate, useParams } from "react-router-dom";
import {
  calculatePlayersPoints,
  determineWinner,
  getCurrentFase,
  getDayOfWeek,
  handleResultsFromDb,
} from "../../helpers/commonFunctions";
import { MainContext } from "../../helpers/MainContext";
import "./resultados.css";

const ResultadosConsulta = () => {
  const [alineacionSede, setAlineacionSede] = useState([]);
  const [nivelSuperior, setNivelSuperior] = useState([]);
  const [walkOverList, setWalkOverList] = useState([]);
  const [walkoverData, setWalkoverData] = useState({});
  const [results, setResults] = useState([]);
  const [alineacionVisitante, setAlineacionVisitante] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const [capitanSede, setCapitanSede] = useState("");
  const [capitanVisitante, setCapitanVisitante] = useState("");
  const [ganador, setGanador] = useState(0);
  const [perdedor, setPerdedor] = useState(0);
  const [totalPointsLoser, setTotalPointsLoser] = useState();
  const [totalPoints, setTotalPoints] = useState();
  const [playerRankingPoints, setPlayerRankingPoints] = useState([]);
  const [itsSede, setItsSede] = useState(false);
  const [nameSede, setNameSede] = useState("");
  const [idResultado, setIdResultado] = useState();
  const [nameVisitante, setNameVisitante] = useState("");
  const [winners, setWinners] = useState();
  const [jornadaData, setJornadaData] = useState();
  const [resultadosGanadores, setResultadosGanadores] = useState();
  const navigate = useNavigate();
  const { userTeamInfo, userInfo, userRol } = useContext(MainContext);
  const [jugadores, setJugadores] = useState([]);
  const { id: idJornada } = useParams();

  const teamsList = [
    {
      key: jornadaData?.idteamsede,
      text: nameSede,
      value: jornadaData?.idteamsede,
    },
    {
      key: jornadaData?.idteamvisitante,
      text: nameVisitante,
      value: jornadaData?.idteamvisitante,
    },
  ];

  const getJornadasData = useCallback(async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/fasesjornadas/getbyid/${idJornada}`)
      .then((res) => setJornadaData(res?.data[0]))
      .catch((error) => console.log(error));
  }, [idJornada]);

  useEffect(() => {
    getJornadasData();
  }, [getJornadasData]);

  const handleChange = (e, { name, value }) =>
    setResults({ ...results, [name]: value });

  const handleWinnersChange = (e, { name, value }) => {
    setResultadosGanadores({ ...resultadosGanadores, [name]: value });
    let winnersCopy = { ...winners };
    winnersCopy = { ...winnersCopy, [name]: value };
    setWinners({ ...winners, [name]: value });
    const finalResults = determineWinner(winnersCopy);
    setGanador(parseInt(finalResults.finalWinner, 10));
    setTotalPoints(finalResults.finalPoints);
    let findPerdedor = finalResults.finalLoser;
    if (finalResults.finalLoser === finalResults.finalWinner) {
      if (parseInt(finalResults.finalWinner, 10) === jornadaData?.idteamsede) {
        findPerdedor = jornadaData?.idteamvisitante;
      } else {
        findPerdedor = jornadaData?.idteamsede;
      }
    }
    setPerdedor(parseInt(findPerdedor, 10));
    setTotalPointsLoser(finalResults.finalPointsLoser);
    let playerTotalPoints = calculatePlayersPoints(
      alineacionSede,
      alineacionVisitante,
      winnersCopy,
      walkOverList,
      userTeamInfo?.idcategory
    );
    setPlayerRankingPoints(playerTotalPoints);
  };
  const getTeamSedeById = useCallback(async (idTeam) => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/teams/getteam/${idTeam}`)
      .then((res) => setNameSede(res.data?.name))
      .catch((error) => console.log(error));
  }, []);
  const getTeamVisitanteById = useCallback(async (idTeam) => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/teams/getteam/${idTeam}`)
      .then((res) => setNameVisitante(res.data?.name))
      .catch((error) => console.log(error));
  }, []);

  const getAlineacion = useCallback(async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/alineaciones/${idJornada}`)
      .then((res) => {
        setAlineacionSede(
          res?.data?.find((item) => item.idequipo === jornadaData?.idteamsede)
        );
        setAlineacionVisitante(
          res?.data?.find(
            (item) => item.idequipo === jornadaData?.idteamvisitante
          )
        );
      })
      .catch((error) => console.log(error));
  }, [idJornada, jornadaData?.idteamsede, jornadaData?.idteamvisitante]);

  const getResultadosGanadores = useCallback(async () => {
    await axios
      .get(
        `https://tennis-open-league-api.vercel.app/v1/resultados/getresultadosganadores/${idJornada}`
      )
      .then((res) => {
        setResultadosGanadores(res.data);
      })
      .catch((error) => console.log(error));
  }, [idJornada]);

  const getWalkoverData = useCallback(async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/getwalkoverlist/${idJornada}`)
      .then((res) => {
        if (res.data) setWalkoverData(res.data);
      })
      .catch((error) => console.log(error));
  }, [idJornada]);

  const getPlayersNivelSuperior = useCallback(async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/nivelsuperior/${idJornada}`)
      .then((res) => {
        if (res.data) setNivelSuperior(res.data);
      })
      .catch((error) => console.log(error));
  }, [idJornada]);

  const getResultados = useCallback(async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/resultados/${idJornada}`)
      .then((res) => {
        if (res?.data && res.data.id) {
          setIdResultado(res.data.id);
          const doblesResult = [
            res.data.doble_1,
            res.data.doble_2,
            res.data.doble_3,
            res.data.doble_4,
            res.data.doble_5,
          ];
          const resultsPerSetTeam = handleResultsFromDb(doblesResult);
          setResults({
            ...res.data,
            ...resultsPerSetTeam,
          });
          setGanador(res.data.ganador);
          setPerdedor(res.data.perdedor);
          if (jornadaData?.idteamsede === res.data.ganador) {
            setTotalPoints(res.data.puntossede);
            setTotalPointsLoser(res.data.puntosvisitante);
          } else {
            setTotalPoints(res.data.puntosvisitante);
            setTotalPointsLoser(res.data.puntossede);
          }
        }
      })
      .catch((error) => console.log(error));
  }, [idJornada]);

  useEffect(() => {
    if (idJornada && userTeamInfo && userTeamInfo.id) getAlineacion();
  }, [idJornada, userTeamInfo, getAlineacion]);

  useEffect(() => {
    if (idJornada) {
      getResultados();
      getResultadosGanadores();
      getWalkoverData();
      getPlayersNivelSuperior();
    }
  }, [
    idJornada,
    getResultados,
    getResultadosGanadores,
    getWalkoverData,
    getPlayersNivelSuperior,
  ]);

  useEffect(() => {
    if (jornadaData) {
      getTeamSedeById(jornadaData?.idteamsede);
      if (userInfo?.idteam === jornadaData?.idteamsede) {
        setItsSede(true);
      } else {
        setItsSede(false);
      }
      getTeamVisitanteById(jornadaData?.idteamvisitante);
    }
  }, [jornadaData, getTeamSedeById, getTeamVisitanteById, userInfo?.idteam]);

  const getPlayersData = useCallback(async () => {
    if (userTeamInfo.id !== undefined) {
      await axios
        .get(
          `https://tennis-open-league-api.vercel.app/v1/user/getallplayers`
        )
        .then((res) => setJugadores(res.data))
        .catch((error) => console.log(error));
    }
  }, [userTeamInfo.id]);

  const cargarRankingJugadores = useCallback(async () => {
    if (playerRankingPoints.length > 0) {
      await axios
        .post(
          `https://tennis-open-league-api.vercel.app/v1/rankingjugador/agregar`,
          playerRankingPoints
        )
        .then((res) => console.log(res.data))
        .catch((error) => console.log(error));
    }
  }, [playerRankingPoints]);

  const handleNivelSuperior = (event) => {
    if (event.target.checked) {
      setNivelSuperior([
        ...nivelSuperior,
        {
          cedula: event.target.value,
          idcategory: userTeamInfo?.idcategory,
          idjornada: idJornada,
        },
      ]);
    } else {
      if (nivelSuperior.some((item) => item.cedula === event.target.value)) {
        let nivelSuperiorCopy = [...nivelSuperior];
        let newNSValues = nivelSuperiorCopy.filter(
          (item) => item.cedula !== event.target.value
        );
        setNivelSuperior(newNSValues);
      }
    }
  };

  const handleWalkOver = (event) => {
    if (event.target.checked) {
      setWalkOverList([...walkOverList, event.target.value]);
    } else {
      if (walkOverList.includes(event.target.value)) {
        let walkOverListCopy = [...walkOverList];
        let newWOValues = walkOverListCopy.filter(
          (item) => item !== event.target.value
        );
        setWalkOverList(newWOValues);
      }
    }
    const fieldName = event.target.value && event.target.value.slice(7);
    setWalkoverData({ ...walkoverData, [fieldName]: event.target.checked });
  };
  useEffect(() => {
    if (userTeamInfo && userTeamInfo.id !== undefined && jornadaData)
      getPlayersData();
  }, [userTeamInfo, getPlayersData, jornadaData]);

  const getPlayersByMainTeam = useCallback(
    async (tipoSede) => {
      let urlId;
      if (tipoSede === "sede") {
        urlId = jornadaData?.idteamsede;
      } else {
        urlId = jornadaData?.idteamvisitante;
      }
      await axios
        .get(`https://tennis-open-league-api.vercel.app/v1/user/getusersbymainteam/${urlId}`)
        .then((res) => {
          if (res && res?.data) {
            if (tipoSede === "sede") {
              setCapitanSede(
                res?.data?.find((item) => item.role === "capitan")?.name
              );
            } else {
              setCapitanVisitante(
                res?.data?.find((item) => item.role === "capitan")?.name
              );
            }
          }
        })
        .catch((error) => console.log(error));
    },
    [jornadaData?.idteamsede, jornadaData?.idteamvisitante]
  );

  const saveTeamRanking = async (team, points) => {
    const payload = {
      idteam: team,
      puntos: points,
      fase: getCurrentFase(),
      idcategory: userTeamInfo?.idcategory,
    };
    await axios
      .post(`https://tennis-open-league-api.vercel.app/v1/rankingequipo/agregar`, payload)
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (jornadaData) {
      getPlayersByMainTeam("sede");
      getPlayersByMainTeam("visitante");
    }
  }, [getPlayersByMainTeam, jornadaData]);

  return (
    <Box
      p={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#03438a",
        minHeight: "100vh",
        fontFamily: "Montserrat",
        width: { md: "80%", xs: "80%" },
        alignItems: "center",
        borderRadius: "7px",
        border: "1px solid #ffffff",
        boxShadow: "4px 4px 4px #03435a",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          backgroundColor: "#03438a",
          width: "100%",
          borderRadius: "3px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            backgroundColor: "#03438a",
            width: "100%",
            borderRadius: "3px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              width: { md: "50%", xs: "100%" },
              textAlign: { md: "left", xs: "center" },
              placeContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { md: "40%", xs: "100%" },
                alignItems: "center",
                paddingTop: { md: 0, xs: "20px" },
                paddingLeft: { md: "10px", xs: 0 },
              }}
            >
              <img
                src={imgResultados}
                alt="Tennis Open League"
                style={{
                  width: window.innerWidth < 768 ? "100px" : "150px",
                  margin: "0 auto",
                  height: "150px",
                }}
              />
            </Box>
            <Box
              p={2}
              sx={{ width: { md: "60%", xs: "100%" }, color: "#ffffff" }}
            >
              <Typography
                sx={{
                  fontFamily: "MontserratBold",
                  fontSize: { md: "1.25rem", xs: "1rem" },
                  marginBottom: "15px",
                }}
              >
                Resultados
              </Typography>
              <Typography sx={{ fontFamily: "Montserrat" }}>
                Consulta de Resultados
              </Typography>
              <Typography sx={{ fontFamily: "Montserrat" }}>
              {`Fase ${getCurrentFase()}`}
              </Typography>
            </Box>
          </Box>
          <Box
            p={2}
            sx={{
              display: "flex",
              width: { md: "50%", xs: "100%" },
              flexDirection: "column",
              alignItems: { md: "flex-end", xs: "center" },
              justifyContent: "flex-start",
            }}
          >
            <GradientButton
              text="VOLVER"
              typeButton="transparent"
              onClick={() => navigate("/dashboard/consultarresultados")}
              extraStyles={{ marginTop: "30px", padding: "5px" }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "100%", paddingTop: "70px" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <Typography
            sx={{
              color: "#ffffff",
              width: "16%",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              backgroundColor: "#ffa436",
              borderRadius: "7px",
              fontWeight: "bold",
              textAlign: "left",
              display: { md: "block", xs: "none" },
            }}
          >
            SEM:
          </Typography>
          <Typography
            sx={{
              color: "#ffffff",
              width: "16%",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              backgroundColor: "#ffa436",
              borderRadius: "7px",
              fontWeight: "bold",
              textAlign: "left",
              display: { md: "block", xs: "none" },
            }}
          >
            Sede:
          </Typography>
          <Typography
            sx={{
              color: "#ffffff",
              width: "16%",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              backgroundColor: "#ffa436",
              borderRadius: "7px",
              fontWeight: "bold",
              textAlign: "left",
              display: { md: "block", xs: "none" },
            }}
          >
            Día:
          </Typography>
          <Typography
            sx={{
              color: "#ffffff",
              width: { md: "16%", xs: "30%" },
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              backgroundColor: "#ffa436",
              borderRadius: "7px",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            Fecha:
          </Typography>
          <Typography
            sx={{
              color: "#ffffff",
              width: { md: "16%", xs: "30%" },
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              backgroundColor: "#ffa436",
              borderRadius: "7px",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            Ganador:
          </Typography>
          <Typography
            sx={{
              color: "#ffffff",
              width: { md: "16%", xs: "30%" },
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              backgroundColor: "#ffa436",
              borderRadius: "7px",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            Fase:
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <Typography
            sx={{
              color: "#ffffff",
              width: "16%",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              backgroundColor: "#0082e5",
              borderRadius: "7px",
              fontWeight: "bold",
              textAlign: "left",
              display: { md: "block", xs: "none" },
            }}
          >
            {jornadaData?.semana}
          </Typography>
          <Typography
            sx={{
              color: "#ffffff",
              width: "16%",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              backgroundColor: "#0082e5",
              borderRadius: "7px",
              fontWeight: "bold",
              textAlign: "left",
              display: { md: "block", xs: "none" },
            }}
          >
            {nameSede}
          </Typography>
          <Typography
            sx={{
              color: "#ffffff",
              width: { md: "16%", xs: "30%" },
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              backgroundColor: "#0082e5",
              borderRadius: "7px",
              fontWeight: "bold",
              textAlign: "left",
              display: { md: "block", xs: "none" },
            }}
          >
            {getDayOfWeek(jornadaData?.fecha)}
          </Typography>
          <Typography
            sx={{
              color: "#ffffff",
              width: { md: "16%", xs: "30%" },
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              backgroundColor: "#0082e5",
              borderRadius: "7px",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            {jornadaData?.fecha}
          </Typography>
          <Typography
            sx={{
              color: "#ffffff",
              width: { md: "16%", xs: "30%" },
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              backgroundColor: "#ffa436",
              borderRadius: "7px",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            {jornadaData?.idteamsede === parseInt(ganador, 10)
              ? nameSede
              : nameVisitante}
          </Typography>
          <Typography
            sx={{
              color: "#ffffff",
              width: { md: "16%", xs: "30%" },
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              backgroundColor: "#ffa436",
              borderRadius: "7px",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            {jornadaData?.tipojornada}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          marginTop={2}
          justifyContent="space-between"
        >
          <Box width="50%">
            <Typography
              sx={{
                color: "#ffffff",
                width: "50%",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#ffa436",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Equipo Sede:
            </Typography>
            <Typography
              sx={{
                color: "#ffffff",
                width: "50%",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#0082e5",
                borderRadius: "7px",
                fontWeight: "bold",
                marginTop: "15px",
                textAlign: "left",
              }}
            >
              {nameSede}
            </Typography>
          </Box>
          <Box width="50%" textAlign="-webkit-right">
            <Typography
              sx={{
                color: "#ffffff",
                width: "50%",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#ffa436",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Equipo Visitante:
            </Typography>
            <Typography
              sx={{
                color: "#ffffff",
                width: "50%",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#0082e5",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
                marginTop: "15px",
              }}
            >
              {nameVisitante}
            </Typography>
          </Box>
        </Box>
        <Form className="contact-form-agregar-resultados">
          <Box sx={{ marginBottom: { md: "25px", xs: "50px" } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ textAlign: "left", color: "#ffffff" }}>
                DOBLE 1
              </Typography>
              <Box display="flex" sx={{ marginLeft: "20px" }}>
                <Typography sx={{ textAlign: "left", color: "#ffffff" }}>
                  WO{" "}
                </Typography>
                <input
                  className="nivel-superior-checkbox"
                  type="checkbox"
                  value="ganadordoble1"
                  disabled={!canEdit}
                  checked={walkoverData?.doble1}
                  onChange={(e) => handleWalkOver(e)}
                  style={{ marginLeft: "10px", width: "20px" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: { md: "row", xs: "column" },
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: { md: "28%", xs: "100%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  display="flex"
                  height="fit-content"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "80%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      height: "fit-content",
                    }}
                  >
                    Nombre y Apellido:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "15%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      height: "fit-content",
                      marginBottom: "20px",
                    }}
                  >
                    NS
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Form.Field
                    id="form-input-control-cedula-11"
                    control={Input}
                    name="cedula-11"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionSede?.doble_11
                      )?.name || ""
                    }
                  />
                  <input
                    className="nivel-superior-checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionSede?.doble_11
                    )}
                    type="checkbox"
                    value={alineacionSede?.doble_11}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Form.Field
                    id="form-input-control-cedula-12"
                    control={Input}
                    name="cedula-12"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionSede?.doble_12
                      )?.name || ""
                    }
                  />
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionSede?.doble_12
                    )}
                    value={alineacionSede?.doble_12}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: { md: "40%", xs: "100%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Dropdown
                  style={{
                    color: "#ffffff",
                    width: "100%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",
                    backgroundColor: "#ffa436",
                    borderRadius: "7px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "20px",
                  }}
                  name="ganadordoble1"
                  value={
                    resultadosGanadores && resultadosGanadores?.ganadordoble1
                  }
                  disabled={!canEdit}
                  placeholder="Seleccione el equipo ganador"
                  onChange={handleWinnersChange}
                  options={teamsList}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "30%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                  >
                    1er Set
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "30%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                  >
                    2do Set
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "30%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "20px !important",
                    }}
                  >
                    3er Set
                  </Typography>
                </Box>
                <Box
                  id="cargar-resultados-sets-1"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Field
                    id="form-input-control-result_111"
                    control={Input}
                    name="result_111"
                    value={results?.result_111}
                    type="text"
                    readOnly
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_112"
                    control={Input}
                    name="result_112"
                    readOnly
                    value={results?.result_112}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_121"
                    control={Input}
                    name="result_121"
                    readOnly
                    type="text"
                    value={results?.result_121}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_122"
                    control={Input}
                    name="result_122"
                    readOnly
                    value={results?.result_122}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_131"
                    control={Input}
                    name="result_131"
                    readOnly
                    value={results?.result_131}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_132"
                    control={Input}
                    name="result_132"
                    readOnly
                    value={results?.result_132}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: { md: "28%", xs: "100%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  display="flex"
                  height="fit-content"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "15%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      order: { ms: 1, xs: 2 },
                    }}
                  >
                    NS
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "80%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      height: "fit-content",
                    }}
                  >
                    Nombre y Apellido:
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionVisitante?.doble_11}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionVisitante?.doble_11
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                  <Form.Field
                    id="form-input-control-cedula-13"
                    control={Input}
                    name="cedula-11"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionVisitante?.doble_11
                      )?.name || ""
                    }
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionVisitante?.doble_12}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionVisitante?.doble_12
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                  <Form.Field
                    id="form-input-control-cedula-14"
                    control={Input}
                    name="cedula-11"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionVisitante?.doble_12
                      )?.name || ""
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ marginBottom: { md: "25px", xs: "50px" } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ textAlign: "left", color: "#ffffff" }}>
                DOBLE 2
              </Typography>
              <Box display="flex" sx={{ marginLeft: "20px" }}>
                <Typography sx={{ textAlign: "left", color: "#ffffff" }}>
                  WO{" "}
                </Typography>
                <input
                  className="nivel-superior-checkbox"
                  type="checkbox"
                  value="ganadordoble2"
                  disabled={!canEdit}
                  checked={walkoverData?.doble2}
                  onChange={(e) => handleWalkOver(e)}
                  style={{ marginLeft: "10px", width: "20px" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: { md: "row", xs: "column" },
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: { md: "28%", xs: "100%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  display="flex"
                  height="fit-content"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "80%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      height: "fit-content",
                    }}
                  >
                    Nombre y Apellido:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "15%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      height: "fit-content",
                    }}
                  >
                    NS
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Form.Field
                    id="form-input-control-cedula-11"
                    control={Input}
                    name="cedula-11"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionSede?.doble_21
                      )?.name || ""
                    }
                  />
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionSede?.doble_21}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionSede?.doble_21
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Form.Field
                    id="form-input-control-cedula-12"
                    control={Input}
                    name="cedula-12"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionSede?.doble_22
                      )?.name || ""
                    }
                  />
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionSede?.doble_22}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionSede?.doble_22
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: { md: "40%", xs: "100%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Dropdown
                  style={{
                    color: "#ffffff",
                    width: "100%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",
                    backgroundColor: "#ffa436",
                    borderRadius: "7px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "20px",
                  }}
                  disabled={!canEdit}
                  name="ganadordoble2"
                  value={
                    resultadosGanadores && resultadosGanadores?.ganadordoble2
                  }
                  placeholder="Seleccione el equipo ganador"
                  onChange={handleWinnersChange}
                  options={teamsList}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "30%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                  >
                    1er Set
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "30%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                  >
                    2do Set
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "30%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "20px !important",
                    }}
                  >
                    3er Set
                  </Typography>
                </Box>
                <Box
                  id="cargar-resultados-sets-2"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Field
                    id="form-input-control-result_211"
                    control={Input}
                    name="result_211"
                    value={results?.result_211}
                    readOnly
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_212"
                    control={Input}
                    name="result_212"
                    value={results?.result_212}
                    readOnly
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_221"
                    control={Input}
                    name="result_221"
                    value={results?.result_221}
                    readOnly
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_222"
                    control={Input}
                    name="result_222"
                    value={results?.result_222}
                    readOnly
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_231"
                    control={Input}
                    name="result_231"
                    value={results?.result_231}
                    readOnly
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_232"
                    control={Input}
                    name="result_232"
                    readOnly
                    value={results?.result_232}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: { md: "28%", xs: "100%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  display="flex"
                  height="fit-content"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "15%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      order: { ms: 1, xs: 2 },
                    }}
                  >
                    NS
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "80%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      height: "fit-content",
                    }}
                  >
                    Nombre y Apellido:
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    value={alineacionVisitante?.doble_21}
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionVisitante?.doble_21
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                  <Form.Field
                    id="form-input-control-cedula-13"
                    control={Input}
                    name="cedula-11"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionVisitante?.doble_21
                      )?.name || ""
                    }
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionVisitante?.doble_22}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionVisitante?.doble_22
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                  <Form.Field
                    id="form-input-control-cedula-14"
                    control={Input}
                    name="cedula-11"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionVisitante?.doble_22
                      )?.name || ""
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ marginBottom: { md: "25px", xs: "50px" } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ textAlign: "left", color: "#ffffff" }}>
                DOBLE 3
              </Typography>
              <Box display="flex" sx={{ marginLeft: "20px" }}>
                <Typography sx={{ textAlign: "left", color: "#ffffff" }}>
                  WO{" "}
                </Typography>
                <input
                  className="nivel-superior-checkbox"
                  type="checkbox"
                  value="ganadordoble3"
                  disabled={!canEdit}
                  checked={walkoverData?.doble3}
                  onChange={(e) => handleWalkOver(e)}
                  style={{ marginLeft: "10px", width: "20px" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: { md: "row", xs: "column" },
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: { md: "28%", xs: "100%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  display="flex"
                  height="fit-content"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "80%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      height: "fit-content",
                    }}
                  >
                    Nombre y Apellido:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "15%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      height: "fit-content",
                    }}
                  >
                    NS
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Form.Field
                    id="form-input-control-cedula-11"
                    control={Input}
                    name="cedula-11"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionSede?.doble_31
                      )?.name || ""
                    }
                  />
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionSede?.doble_31}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionSede?.doble_31
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Form.Field
                    id="form-input-control-cedula-12"
                    control={Input}
                    name="cedula-12"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionSede?.doble_32
                      )?.name || ""
                    }
                  />
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionSede?.doble_32}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionSede?.doble_32
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: { md: "40%", xs: "100%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Dropdown
                  style={{
                    color: "#ffffff",
                    width: "100%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",
                    backgroundColor: "#ffa436",
                    borderRadius: "7px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "20px",
                  }}
                  name="ganadordoble3"
                  value={
                    resultadosGanadores && resultadosGanadores?.ganadordoble3
                  }
                  placeholder="Seleccione el equipo ganador"
                  onChange={handleWinnersChange}
                  options={teamsList}
                  disabled={!canEdit}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "30%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                  >
                    1er Set
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "30%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                  >
                    2do Set
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "30%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "20px !important",
                    }}
                  >
                    3er Set
                  </Typography>
                </Box>
                <Box
                  id="cargar-resultados-sets-3"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Field
                    id="form-input-control-result_311"
                    control={Input}
                    name="result_311"
                    value={results?.result_311}
                    readOnly
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_312"
                    control={Input}
                    name="result_312"
                    value={results?.result_312}
                    readOnly
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_321"
                    control={Input}
                    name="result_321"
                    readOnly
                    value={results?.result_321}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_322"
                    control={Input}
                    name="result_322"
                    readOnly
                    value={results?.result_322}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_331"
                    control={Input}
                    name="result_331"
                    readOnly
                    value={results?.result_331}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_332"
                    control={Input}
                    name="result_332"
                    readOnly
                    value={results?.result_332}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: { md: "28%", xs: "100%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  display="flex"
                  height="fit-content"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "15%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      order: { ms: 1, xs: 2 },
                    }}
                  >
                    NS
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "80%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      height: "fit-content",
                    }}
                  >
                    Nombre y Apellido:
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionVisitante?.doble_31}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionVisitante?.doble_31
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                  <Form.Field
                    id="form-input-control-cedula-13"
                    control={Input}
                    name="cedula-11"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionVisitante?.doble_31
                      )?.name || ""
                    }
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionVisitante?.doble_32}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionVisitante?.doble_32
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                  <Form.Field
                    id="form-input-control-cedula-14"
                    control={Input}
                    name="cedula-11"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionVisitante?.doble_32
                      )?.name || ""
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ marginBottom: { md: "25px", xs: "50px" } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ textAlign: "left", color: "#ffffff" }}>
                DOBLE 4
              </Typography>
              <Box display="flex" sx={{ marginLeft: "20px" }}>
                <Typography sx={{ textAlign: "left", color: "#ffffff" }}>
                  WO{" "}
                </Typography>
                <input
                  className="nivel-superior-checkbox"
                  type="checkbox"
                  value="ganadordoble4"
                  disabled={!canEdit}
                  checked={walkoverData?.doble4}
                  onChange={(e) => handleWalkOver(e)}
                  style={{ marginLeft: "10px", width: "20px" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: { md: "row", xs: "column" },
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: { md: "28%", xs: "100%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  display="flex"
                  height="fit-content"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "80%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      height: "fit-content",
                    }}
                  >
                    Nombre y Apellido:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "15%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      height: "fit-content",
                    }}
                  >
                    NS
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Form.Field
                    id="form-input-control-cedula-11"
                    control={Input}
                    name="cedula-11"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionSede?.doble_41
                      )?.name || ""
                    }
                  />
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionSede?.doble_41}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionSede?.doble_41
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Form.Field
                    id="form-input-control-cedula-12"
                    control={Input}
                    name="cedula-12"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionSede?.doble_42
                      )?.name || ""
                    }
                  />
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionSede?.doble_42}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionSede?.doble_42
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: { md: "40%", xs: "100%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Dropdown
                  style={{
                    color: "#ffffff",
                    width: "100%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",
                    backgroundColor: "#ffa436",
                    borderRadius: "7px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "20px",
                  }}
                  name="ganadordoble4"
                  value={
                    resultadosGanadores && resultadosGanadores?.ganadordoble4
                  }
                  placeholder="Seleccione el equipo ganador"
                  onChange={handleWinnersChange}
                  options={teamsList}
                  disabled={!canEdit}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "30%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                  >
                    1er Set
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "30%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                  >
                    2do Set
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "30%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "20px !important",
                    }}
                  >
                    3er Set
                  </Typography>
                </Box>
                <Box
                  id="cargar-resultados-sets-4"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Field
                    id="form-input-control-result_411"
                    control={Input}
                    name="result_411"
                    readOnly
                    value={results?.result_411}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_412"
                    control={Input}
                    name="result_412"
                    readOnly
                    value={results?.result_412}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_421"
                    control={Input}
                    name="result_421"
                    readOnly
                    value={results?.result_421}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_422"
                    control={Input}
                    name="result_422"
                    readOnly
                    value={results?.result_422}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_431"
                    control={Input}
                    name="result_431"
                    readOnly
                    value={results?.result_431}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_432"
                    control={Input}
                    name="result_432"
                    readOnly
                    value={results?.result_432}
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: { md: "28%", xs: "100%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  display="flex"
                  height="fit-content"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "15%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      order: { ms: 1, xs: 2 },
                    }}
                  >
                    NS
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "80%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      height: "fit-content",
                    }}
                  >
                    Nombre y Apellido:
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    value={alineacionVisitante?.doble_41}
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionVisitante?.doble_41
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                  <Form.Field
                    id="form-input-control-cedula-13"
                    control={Input}
                    name="cedula-11"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionVisitante?.doble_41
                      )?.name || ""
                    }
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionVisitante?.doble_42}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionVisitante?.doble_42
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                  <Form.Field
                    id="form-input-control-cedula-14"
                    control={Input}
                    name="cedula-11"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionVisitante?.doble_42
                      )?.name || ""
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ marginBottom: { md: "25px", xs: "50px" } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ textAlign: "left", color: "#ffffff" }}>
                DOBLE 5
              </Typography>
              <Box display="flex" sx={{ marginLeft: "20px" }}>
                <Typography sx={{ textAlign: "left", color: "#ffffff" }}>
                  WO{" "}
                </Typography>
                <input
                  className="nivel-superior-checkbox"
                  type="checkbox"
                  value="ganadordoble5"
                  disabled={!canEdit}
                  checked={walkoverData?.doble5}
                  onChange={(e) => handleWalkOver(e)}
                  style={{ marginLeft: "10px", width: "20px" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: { md: "row", xs: "column" },
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: { md: "28%", xs: "100%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  display="flex"
                  height="fit-content"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "80%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      height: "fit-content",
                    }}
                  >
                    Nombre y Apellido:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "15%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      height: "fit-content",
                    }}
                  >
                    NS
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Form.Field
                    id="form-input-control-cedula-11"
                    control={Input}
                    name="cedula-11"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionSede?.doble_51
                      )?.name || ""
                    }
                  />
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionSede?.doble_51}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionSede?.doble_51
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Form.Field
                    id="form-input-control-cedula-12"
                    control={Input}
                    name="cedula-12"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionSede?.doble_52
                      )?.name || ""
                    }
                  />
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionSede?.doble_52}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionSede?.doble_52
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: { md: "40%", xs: "100%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Dropdown
                  style={{
                    color: "#ffffff",
                    width: "100%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",
                    backgroundColor: "#ffa436",
                    borderRadius: "7px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "20px",
                  }}
                  name="ganadordoble5"
                  value={
                    resultadosGanadores && resultadosGanadores?.ganadordoble5
                  }
                  placeholder="Seleccione el equipo ganador"
                  onChange={handleWinnersChange}
                  options={teamsList}
                  disabled={!canEdit}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "30%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                  >
                    1er Set
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "30%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                  >
                    2do Set
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "30%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "20px !important",
                    }}
                  >
                    3er Set
                  </Typography>
                </Box>
                <Box
                  id="cargar-resultados-sets-5"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Field
                    id="form-input-control-result_511"
                    control={Input}
                    name="result_511"
                    value={results?.result_511}
                    readOnly
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_512"
                    control={Input}
                    name="result_512"
                    value={results?.result_512}
                    readOnly
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_521"
                    control={Input}
                    name="result_521"
                    value={results?.result_521}
                    readOnly
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_522"
                    control={Input}
                    name="result_522"
                    value={results?.result_522}
                    readOnly
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_531"
                    control={Input}
                    name="result_531"
                    value={results?.result_531}
                    readOnly
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                  <Form.Field
                    id="form-input-control-result_532"
                    control={Input}
                    name="result_532"
                    value={results?.result_532}
                    readOnly
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: { md: "28%", xs: "100%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  display="flex"
                  height="fit-content"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "15%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      order: { ms: 1, xs: 2 },
                    }}
                  >
                    NS
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      width: "80%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      backgroundColor: "#ffa436",
                      borderRadius: "7px",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "20px",
                      height: "fit-content",
                    }}
                  >
                    Nombre y Apellido:
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionVisitante?.doble_51}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionVisitante?.doble_51
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                  <Form.Field
                    id="form-input-control-cedula-13"
                    control={Input}
                    name="cedula-11"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionVisitante?.doble_51
                      )?.name || ""
                    }
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <input
                    className="nivel-superior-checkbox"
                    type="checkbox"
                    disabled={
                      !canEdit ||
                      results?.confirmadosede ||
                      results?.confirmadovisitante
                    }
                    value={alineacionVisitante?.doble_52}
                    checked={nivelSuperior?.some(
                      (item) => item.cedula === alineacionVisitante?.doble_52
                    )}
                    onChange={(e) => handleNivelSuperior(e)}
                  />
                  <Form.Field
                    id="form-input-control-cedula-14"
                    control={Input}
                    name="cedula-11"
                    readOnly
                    type="text"
                    value={
                      jugadores.find(
                        (item) => item.cedula === alineacionVisitante?.doble_52
                      )?.name || ""
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "none",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                width: "50%",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  marginTop: "30px",
                  color: "#ffff",
                  textAlign: "left",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  width: { md: "35%", xs: "90%" },
                  marginBottom: "20px",
                }}
              >
                Capitan Sede:
              </Typography>
              <Form.Field
                id="form-input-control-cedula-14"
                control={Input}
                name="cedula-11"
                value={capitanSede}
                readOnly
                type="text"
                style={{ width: window.innerWidth < 767 ? "100%" : "45%" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                width: "50%",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  marginTop: "30px",
                  color: "#ffff",
                  textAlign: "left",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  width: { md: "35%", xs: "100%" },
                  marginBottom: "20px",
                }}
              >
                Capitan Visitante:
              </Typography>
              <Form.Field
                id="form-input-control-cedula-14"
                control={Input}
                name="cedula-11"
                value={capitanVisitante}
                readOnly
                type="text"
                style={{ width: window.innerWidth < 767 ? "100%" : "45%" }}
              />
            </Box>
          </Box>
          {userRol === "admin" && (
            <>
              <Box sx={{ marginTop: { md: "10px", xs: "30px" } }}>
                <Typography
                  sx={{
                    marginTop: "30px",
                    color: "#ffff",
                    textAlign: "left",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",
                    backgroundColor: "#ffa436",
                    borderRadius: "7px",
                    fontWeight: "bold",
                    width: "130px",
                    marginBottom: "20px",
                  }}
                >
                  Observaciones equipo sede:
                </Typography>
                <TextArea
                  disabled={!itsSede || !canEdit}
                  name="observaciones"
                  value={results?.observaciones}
                  style={{ backgroundColor: "#0082e5", color: "#ffffff" }}
                  //onChange={handleChange}
                ></TextArea>
              </Box>
              <Box sx={{ marginTop: { md: "20px", xs: "30px" } }}>
                <Typography
                  sx={{
                    marginTop: "30px",
                    color: "#ffff",
                    textAlign: "left",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "10px",
                    backgroundColor: "#ffa436",
                    borderRadius: "7px",
                    fontWeight: "bold",
                    width: "130px",
                    marginBottom: "20px",
                  }}
                >
                  Observaciones equipo visitante:
                </Typography>
                <TextArea
                  disabled={!itsSede || !canEdit}
                  name="observaciones"
                  value={results?.observacionesvisitante}
                  style={{ backgroundColor: "#0082e5", color: "#ffffff" }}
                  //onChange={handleChange}
                ></TextArea>
              </Box>
            </>
          )}
        </Form>
      </Box>
    </Box>
  );
};

export default ResultadosConsulta;
