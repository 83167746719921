import React, { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { Form, Input, Button, Dropdown } from "semantic-ui-react";
import axios from "axios";
import imgGestionarUsuarios from "../../assets/dashboard/icono_vista_gestionar-usuarios.svg";
import Modal from "../../components/ModalPopUp/Modal";
import ModalMsg from "../../components/ModalPopUp/ModalMsg";
import GradientButton from "../../components/GradientButton";
import { useNavigate } from "react-router-dom";
import "./administracion.css";

const Administracion = () => {
  const [idJornada, setIdJornada] = useState();
  const [teamsData, setTeamsData] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [idJornadaResultados, setIdJornadaResultados] = useState();

  const [show, setShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({ type: "", text: "" });
  const navigate = useNavigate();

  const handleChange = (e, { name, value }) => {
    setSelectedTeam(value);
  };

  const getDataByJornada = async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/alineacionesequipos/${idJornada}`)
      .then((res) => setTeamsData(res.data))
      .catch((error) => console.log(error));
  };

  const habilitarJornada = async () => {
    await axios
      .put(
        `https://tennis-open-league-api.vercel.app/v1/updatealineaciones/${idJornada}/${selectedTeam}`
      )
      .then((res) => {
        if (res?.data) {
          setShow(true);
          setModalInfo({
            type: "success",
            text: "Alineación habilitada para editar!",
          });
        }
      })
      .catch((error) => {
        setShow(true);
        setModalInfo({
          type: "error",
          text: "Hubo un error!",
        });
      });
  };

  const habilitarResultados = async () => {
    await axios
      .put(
        `https://tennis-open-league-api.vercel.app/v1/resultados/habilitar/${idJornadaResultados}`
      )
      .then((res) => {
        if (res?.data) {
          setShow(true);
          setModalInfo({
            type: "success",
            text: "Resultados habilitados para editar!",
          });
        }
      })
      .catch((error) => {
        setShow(true);
        setModalInfo({
          type: "error",
          text: "Hubo un error!",
        });
      });
  };

  const teamOptions =
    teamsData &&
    teamsData.map((team) => ({
      key: team.id,
      text: `${team.name}`,
      value: team.id,
    }));

  return (
    <Box
      p={2}
      id="gestionar-usuarios"
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#03438a",
        minHeight: "100vh",
        fontFamily: "Montserrat",
        width: { md: "80%", xs: "80%" },
        alignItems: "start",
        borderRadius: "7px",
        border: "1px solid #ffffff",
        boxShadow: "4px 4px 4px #03435a",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          backgroundColor: "#03438a",
          width: "100%",
          borderRadius: "3px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            width: { md: "50%", xs: "100%" },
            textAlign: { md: "left", xs: "center" },
            placeContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { md: "40%", xs: "100%" },
              alignItems: "center",
              paddingTop: { md: 0, xs: "20px" },
              paddingLeft: { md: "10px", xs: 0 },
            }}
          >
            <img
              src={imgGestionarUsuarios}
              alt="Tennis Open League"
              style={{
                width: window.innerWidth < 768 ? "100px" : "150px",
                margin: "0 auto",
                height: "150px",
              }}
            />
          </Box>
          <Box
            p={2}
            sx={{ width: { md: "60%", xs: "100%" }, color: "#ffffff" }}
          >
            <Typography
              sx={{
                fontFamily: "MontserratBold",
                fontSize: { md: "1.25rem", xs: "1rem" },
                marginBottom: "15px",
              }}
            >
              Administración
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Escriba los campos necesarios para editar
            </Typography>
          </Box>
        </Box>
        <Box
          p={2}
          sx={{
            display: "flex",
            width: "50%",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
          }}
        >
          <GradientButton
            text="VOLVER"
            typeButton="transparent"
            onClick={() => navigate("/dashboard")}
            extraStyles={{ marginTop: "30px", padding: "5px" }}
          />
        </Box>
      </Box>
      <Box
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          color: "#ffffff",
          maxWidth: "-webkit-fill-available",
          width: "100%",
        }}
      >
        <Divider color="white" />
        <Box sx={{ padding: "10px", display: "flex", flexDirection: "column" }}>
          <Box sx={{ width: "100%", marginBottom: "20px" }}>
            <Typography sx={{ fontFamily: "Montserrat", fontSize: "16px" }}>
              Habilitar edición de alineación
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "30px" }}
          >
            <Box sx={{ width: "50%" }}>
              <Form.Field
                id="form-input-control-administracion"
                control={Input}
                label="Numero de jornada: &nbsp;&nbsp;"
                name="jugador"
                placeholder="Introduce el idjornada"
                value={idJornada}
                required
                onChange={(e) => setIdJornada(e.target.value)}
              />
            </Box>
            <Dropdown
              placeholder="Equipo"
              name="idteam"
              labeled
              search
              selection
              value={selectedTeam}
              disabled={!teamsData ? true : false}
              options={teamOptions}
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Button
              type="submit"
              style={{
                backgroundColor: "#ffa436",
                border: "none",
                padding: "4px 20px",
                marginLeft: "20px",
                boxShadow: "3px 3px 3px #03435a",
                cursor: "pointer",
                borderRadius: "5px",
              }}
              onClick={getDataByJornada}
            >
              <p
                style={{
                  color: "#ffffff",
                }}
              >
                Buscar
              </p>{" "}
            </Button>
            <Button
              type="submit"
              style={{
                backgroundColor: "#ffa436",
                border: "none",
                padding: "4px 20px",
                marginLeft: "20px",
                boxShadow: "3px 3px 3px #03435a",
                cursor: "pointer",
                borderRadius: "5px",
              }}
              onClick={habilitarJornada}
              disabled={idJornada && selectedTeam ? false : true}
            >
              <p
                style={{
                  color: "#ffffff",
                }}
              >
                Habilitar
              </p>{" "}
            </Button>
          </Box>
        </Box>
        <Divider color="white" sx={{ marginTop: "70px" }} />
        <Box sx={{ padding: "10px", display: "flex", flexDirection: "column" }}>
          <Box sx={{ width: "100%", marginBottom: "20px" }}>
            <Typography sx={{ fontFamily: "Montserrat", fontSize: "16px" }}>
              Habilitar edición de resultados
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "30px" }}
          >
            <Box sx={{ width: "50%" }}>
              <Form.Field
                id="form-input-control-administracion"
                control={Input}
                label="Numero de jornada: &nbsp;&nbsp;"
                name="jugador"
                placeholder="Introduce el idjornada"
                value={idJornadaResultados}
                required
                onChange={(e) => setIdJornadaResultados(e.target.value)}
              />
            </Box>
            {/*<Dropdown
              placeholder="Equipo"
              name="idteam"
              labeled
              search
              selection
              value={selectedTeam}
              disabled={!teamsData ? true : false}
              options={teamOptions}
              onChange={handleChange}
            />*/}
          </Box>
          <Box sx={{ display: "flex" }}>
            <Button
              type="submit"
              style={{
                backgroundColor: "#ffa436",
                border: "none",
                padding: "4px 20px",
                marginLeft: "20px",
                boxShadow: "3px 3px 3px #03435a",
                cursor: "pointer",
                borderRadius: "5px",
              }}
              onClick={habilitarResultados}
              disabled={idJornadaResultados ? false : true}
            >
              <p
                style={{
                  color: "#ffffff",
                }}
              >
                Habilitar
              </p>{" "}
            </Button>
          </Box>
        </Box>
        <Divider color="white" sx={{ marginTop: "15px" }} />
      </Box>
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <ModalMsg typeModal={modalInfo.type} textModal={modalInfo.text} />
      </Modal>
    </Box>
  );
};

export default Administracion;
