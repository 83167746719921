import { Box, Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const DashboardCard = ({ imgSrc, linkSrc, onClick }) => {
  const imgStyles =
    window.innerWidth < 768
      ? {
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }
      : { height: "100%", width: "100%", objectFit: "cover" };
  return (
    <Grid
      item
      xs={12}
      md={4}
      sx={{ textAlign: { md: "auto", xs: "-webkit-center" } }}
    >
      <Box
        sx={{
          color: "white",
          p: 2,
          backgroundColor: "#03438a",
          borderRadius: "5px",
          height: { md: "210px", xs: "190px" },
          width: { md: "210px", xs: "190px" },
          border:"1px solid #ffff"
        }}
      >
        <Box
          sx={{
            border: "1px solid #03438a",
            boxShadow: "1px 1px 1px #03435a",
            display: "flex",
            justifyContent: "center",
            height: "100%",
            position: "relative",
            zIndex: 0,
            "&:hover": {
              opacity: "0.5",
              transition: "all 0.7s ease-in-out",
            },
          }}
        >
          <Link
            onClick={() => {
              if (linkSrc === "/cerrarsesion") {
                sessionStorage.setItem("token", "");
              }
            }}
            to={linkSrc === "/cerrarsesion" ? "/iniciarsesion" : linkSrc}
            style={{ position: "relative" }}
            target={
              linkSrc ===
              "https://drive.google.com/drive/folders/1sFxkZp_vTjpVKmK7tcDm1Y4kkcFszE9B"
                ? "_blank"
                : null
            }
          >
            <img
              src={imgSrc}
              alt="Tennis Open League"
              className="main-img-card-dashboard"
              style={{ ...imgStyles, position: "relative", zIndex: "-50" }}
              onClick={onClick}
            />
          </Link>
        </Box>
      </Box>
    </Grid>
  );
};
export default DashboardCard;
