import mainInicioImg from "../assets/Foto_Header_desktop.jpg";

export const MainBox = {
  backgroundImage: `url('${mainInicioImg}')`,
  backgroundSize: "cover",
  height: "auto",
};

export const MainBoxStyles = {
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "space-between",
  padding: "10px 20px",
};

export const longTextStyles = {
  fontFamily: "Montserrat",
  color: "#ffffff",
  width: "80%",
  padding: "20px 0 20px 0",
};
export const longTextStylesBold = {
  ...longTextStyles,
  fontWeight: "bold",
};

export const menuItemsList = [
  "Inicio",
  "Quiénes Somos",
  "Torneo",
  "Patrocinantes",
  "Contáctanos",
  "Eventos",
];

export const paperPropsDefault = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

// PAGE: INICIO
export const TextInicio = ["Bienvenidos", "TennisOpen", "League"];
export const LongTextInicio =
  "Descubre la emoción del tenis con Tennis Open League. Desafía tus límites en la cancha y disfruta del espíritu competitivo, te damos la bienvenida a nuestra comunidad dedicada al tenis.";
export const Slogan = "¡Únete en esta emocionante jornada con nosotros!";
export const buttonTexts = ["Inicia Sesión", "Inscribir Equipo", "Contáctanos"];
export const tennisOpenLeagueText = "BIENVENIDOS";

// PAGE: ABOUT US
export const aboutUsTitle = "QUIÉNES SOMOS";
export const aboutUsText = [
  "Más que un torneo, somos una comunidad apasionada por el tenis",
  "En Tennis Open League, vamos más allá de la competencia. Somos una comunidad apasionada en crear experiencias memorables en el mundo del tenis.",
  "Nuestro compromiso se extiende a todos los amantes de este deporte, desde jugadores novatos hasta profesionales experimentados.",
  "Nuestros Pilares:",
];

export const aboutUsPilares = [
  "Calidad Deportiva: Elevamos la calidad de los torneos para ofrecer experiencias competitivas de primer nivel.",
  "Fomento del interés: Inspiramos el interés y la participación en el tenis a través de actividades innovadoras.",
  "Comunidad Unida: Creamos una comunidad unida, donde la competencia saludable se combina con el compañerismo y el respeto mutuo.",
];

export const aboutUsSlogan = ["Más que un deporte... Un estilo de vida!"];
// Page Torneo
export const calendario = [
  {
    primary: "LUNES 15 DE ENERO DE 2024",
    secundary: "Pre-inscripciones (PRE-VENTA)",
  },
  {
    primary: "LUNES 22 DE ENERO DE 2024",
    secundary: "Inscripciones Generales",
  },
  {
    primary: "DOMINGO 11 DE FEBRERO DE 2024",
    secundary: "Cierre de Inscripciones",
  },
  {
    primary: "LUNES 19 DE FEBRERO DE 2024",
    secundary: "Sorteo de jornadas",
  },
];

export const categorias = [
  "Femenino 6ta.",
  "Femenino 5ta.",
  "Femenino 4ta",
  "Femenino 4ta/3era.",
  "Masculino 6ta.",
  "Masculino 5ta.",
  "Masculino 4ta/3era.",
  "Mixto",
  "Juvenil",
];

export const calendarioCategorias = ["CALENDARIO", ".", "CATEGORIAS"];

//Page Patrocinantes
export const patrocinantesTitle = "PATROCINANTES";
export const patrocinantesText = [
  "Bienvenido a nuestra comunidad, donde la colaboración y la calidad se unen. Nos enorgullece contar con el apoyo de nuestras marcas excepcionales que comparten nuestra visión y valores. Conoce a nuestros patrocinantes y disfruta de beneficios exclusivos al ser parte de nuestra familia.",
  "Descubre la excelencia en cada producto de RS PERFORMANCE. Como patrocinante oficial, ofrecen a nuestra comunidad promociones exclusivas y acceso anticipado a sus últimas colecciones. ¡Explora su mundo ahora!",
];

export const contactPageText = [
  "¿Quieres ser parte de la Tennis Open League?",
  "Contáctanos para obtener más información acerca de la liga, equipos, jugadores y patrocinantes.",
  "¡Únete a nosotros!",
];

export const passwordPattern = {
  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{16,}$/,
  message:
    "Password must contain at least: 16 characters, 1 digit, 1 uppercase. No special characters allowed.",
};

export const rolesList = [
  { key: 11012024, text: "Super Admin", value: "super-admin" },
  { key: 11022024, text: "Admin", value: "admin" },
  { key: 3, text: "Staff", value: "staff" },
  { key: 4, text: "Delegado", value: "delegado" },
  { key: 5, text: "Capitán", value: "capitan" },
  { key: 6, text: "Sub Capitán", value: "sub-capitan" },
  { key: 7, text: "Jugador", value: "jugador" },
];

export const categoriasList = [
  { key: 1, text: "Femenino 6ta.", value: "femenino6ta" },
  { key: 2, text: "Femenino 5ta.", value: "femenino5ta" },
  { key: 3, text: "Femenino 4ta/3era.", value: "femenino4ta3era" },
  { key: 4, text: "Masculino 6ta.", value: "masculino6ta" },
  { key: 5, text: "Masculino 5ta.", value: "masculino5ta" },
  { key: 6, text: "Masculino 4ta/3era.", value: "masculino4ta3era" },
  { key: 7, text: "Mixto", value: "mixto" },
  { key: 8, text: "Juvenil", value: "juvenil" },
];

export const sexoList = [
  { key: 1, text: "Masculino", value: "m" },
  { key: 2, text: "Femenino", value: "f" },
];

// Dashboard
export const dashboardLinksAdmin = [
  { name: "Perfil", path: "/dashboard/profile" },
  { name: "Ranking Jugadores", path: "/dashboard/rankingjugadores" },
  { name: "Calendario", path: "/dashboard/calendario" },
  { name: "Resultados", path: "/dashboard/resultados" },
  { name: "Consultar Resultados", path: "/dashboard/consultarresultados" },
  { name: "Cargar Tabla de Posiciones", path: "/dashboard/cargartablaposiciones" },
  { name: "Tabla de Posiciones", path: "/dashboard/vertablaposiciones" },
  { name: "Nivel Superior", path: "/dashboard/nivelsuperior" },
  { name: "Agregar Fase Jornada", path: "/dashboard/agregarfasejornada" },
  { name: "Modificar Usuarios", path: "/dashboard/modificarusuarios" },
  { name: "Modificar Equipos", path: "/dashboard/modificarequipos" },
  { name: "Administración", path: "/dashboard/administracion" },
  { name: "Buscar Equipo", path: "/dashboard/buscarequipo" },
  { name: "Reglamento", path: "https://drive.google.com/drive/folders/1sFxkZp_vTjpVKmK7tcDm1Y4kkcFszE9B" },
  { name: "Alineación", path: "/dashboard/alineacion" },
  { name: "Organigrama", path: "/dashboard/organigrama" },
  { name: "Cerrar Sesión", path: "/dashboard/cerrarsesion" },
];

export const dashboardLinksCapitan = [
  { name: "Perfil", path: "/dashboard/profile" },
  { name: "Ranking Jugadores", path: "/dashboard/rankingjugadores" },
  { name: "Calendario", path: "/dashboard/calendario" },
  { name: "Resultados", path: "/dashboard/resultados" },
  { name: "Tabla de Posiciones", path: "/dashboard/vertablaposiciones" },
  { name: "Consultar Resultados", path: "/dashboard/consultarresultados" },
  { name: "Buscar Equipo", path: "/dashboard/buscarequipo" },
  { name: "Reglamento", path: "https://drive.google.com/drive/folders/1sFxkZp_vTjpVKmK7tcDm1Y4kkcFszE9B" },
  { name: "Alineación", path: "/dashboard/alineacion" },
  { name: "Organigrama", path: "/dashboard/organigrama" },
  { name: "Cerrar Sesión", path: "/dashboard/cerrarsesion" },
];

export const dashboardLinksStaff = [
  { name: "Perfil", path: "/dashboard/profile" },
  { name: "Ranking Jugadores", path: "/dashboard/rankingjugadores" },
  { name: "Calendario", path: "/dashboard/calendario" },
  { name: "Modificar Usuarios", path: "/dashboard/modificarusuarios" },
  { name: "Buscar Equipo", path: "/dashboard/buscarequipo" },
  { name: "Resultados", path: "/dashboard/resultados" },
  { name: "Tabla de Posiciones", path: "/dashboard/vertablaposiciones" },
  { name: "Consultar Resultados", path: "/dashboard/consultarresultados" },
  { name: "Reglamento", path: "https://drive.google.com/drive/folders/1sFxkZp_vTjpVKmK7tcDm1Y4kkcFszE9B" },
  { name: "Organigrama", path: "/dashboard/organigrama" },
  { name: "Cerrar Sesión", path: "/dashboard/cerrarsesion" },
];

export const dashboardLinksJugador = [
  { name: "Perfil", path: "/dashboard/profile" },
  { name: "Ranking Jugadores", path: "/dashboard/rankingjugadores" },
  { name: "Calendario", path: "/dashboard/calendario" },
  { name: "Tabla de Posiciones", path: "/dashboard/vertablaposiciones" },
  { name: "Consultar Resultados", path: "/dashboard/consultarresultados" },
  { name: "Buscar Equipo", path: "/dashboard/buscarequipo" },
  { name: "Reglamento", path: "https://drive.google.com/drive/folders/1sFxkZp_vTjpVKmK7tcDm1Y4kkcFszE9B" },
  { name: "Organigrama", path: "/dashboard/organigrama" },
  { name: "Cerrar Sesión", path: "/dashboard/cerrarsesion" },
];
