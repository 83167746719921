export const categories = [
  { key: 1, text: "6ta Femenino", value: 1 },
  { key: 2, text: "5ta Femenino", value: 2 },
  { key: 3, text: "4ta/3era Femenino", value: 3 },
  { key: 4, text: "6ta Masculino", value: 4 },
  { key: 5, text: "5ta Masculino", value: 5 },
  { key: 6, text: "4ta/3era Masculino", value: 6 },
  { key: 7, text: "Mixto", value: 7 },
  { key: 8, text: "Juvenil", value: 8 },
];

export const categoriesSinMixto = [
  { key: 1, text: "6ta Femenino", value: 1 },
  { key: 2, text: "5ta Femenino", value: 2 },
  { key: 3, text: "4ta Femenino", value: 3 },
  { key: 4, text: "3era Femenino", value: 4 },
  { key: 5, text: "6ta Masculino", value: 5 },
  { key: 6, text: "5ta Masculino", value: 6 },
  { key: 7, text: "4ta Masculino", value: 7 },
  { key: 8, text: "3era Masculino", value: 8 },
];

export const categoriesPlayer = [
  { key: 1, text: "6ta Femenino", value: 1 },
  { key: 2, text: "5ta Femenino", value: 2 },
  { key: 3, text: "4ta Femenino", value: 3 },
  { key: 4, text: "3era Femenino", value: 4 },
  { key: 5, text: "6ta Masculino", value: 5 },
  { key: 6, text: "5ta Masculino", value: 6 },
  { key: 7, text: "4ta Masculino", value: 7 },
  { key: 8, text: "3era Masculino", value: 8 },
  { key: 9, text: "Mixto", value: 9 },
  { key: 10, text: "Juvenil", value: 10 },
];
