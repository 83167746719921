import { Box, Grid } from "@mui/material";
import React, { useContext } from "react";
import DashboardCard from "./DashboardCard";
import { MainContext } from "../../helpers/MainContext";
import { getDashboardImagesdListByRole } from "../../helpers/commonFunctions";

const DashboardGrid = () => {
  const { userRol } = useContext(MainContext);
  const dashboardImagesListByRole = getDashboardImagesdListByRole(userRol);
  return (
    <Box
      sx={{
        padding: "0px 20px 20px 30px",
        display: "flex",
        placeContent: "center",
      }}
    >
      <Grid container spacing={5} sx={{ margin: { md: "auto", xs: 0 } }}>
        {dashboardImagesListByRole && dashboardImagesListByRole.map((item, key) => (
          <DashboardCard imgSrc={item.img} linkSrc={item.url} key={key} />
        ))}
      </Grid>
    </Box>
  );
};
export default DashboardGrid;
