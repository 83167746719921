import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import logoTOLBlanco from "../../assets/Logo_SoftBlue_Footer.png";
import { MainContext } from "../../helpers/MainContext";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { decryptData } from "../../helpers/forAuth";
import axios from "axios";
import defaultUserPicture from "../../assets/foto_perfil_defecto.svg";
import {
  getCategoryInfo,
  getCurrentFase,
  getDashboardListByRole,
} from "../../helpers/commonFunctions";

const Dashboard = () => {
  const {
    updateUserInfo,
    userInfo,
    updateAuthStatus,
    updateUserPicture,
    updateUserRol,
    userRol,
    updateTeamsInfo,
    updateUserTeamInfo,
    updateJornadasData,
  } = useContext(MainContext);
  const [userToken, setUserToken] = useState(sessionStorage.getItem("token"));

  const navigate = useNavigate();
  useEffect(() => {
    if (userToken !== "") {
      getUserData(userToken);
      getTeamsData();
    }
  }, [userToken]);

  const getTeamsData = async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/teams/getteams`)
      .then((res) => updateTeamsInfo(res.data))
      .catch((error) => console.log(error));
  };

  //localStorage.setItem('filtrosBusqueda', '');
  const getUserTeamData = async (idTeam) => {
    await axios
      .get(
        `https://tennis-open-league-api.vercel.app/v1/teams/getteam/${idTeam}`
      )
      .then((res) =>
        updateUserTeamInfo({
          ...res.data,
          category: getCategoryInfo(res.data.idcategory),
        })
      )
      .catch((error) => console.log(error));
  };

  const logoTOLStyles =
    window.innerWidth < 768
      ? { width: "40%", margin: "0 auto" }
      : { width: "50%" };

  const getUserData = async (userInfo) => {
    if (userInfo) {
      let userId = await decryptData(userInfo);
      await axios
        .get(
          `https://tennis-open-league-api.vercel.app/v1/user/getuserid/${userId}`
        )
        .then((res) => {
          updateUserInfo(res.data);
          updateAuthStatus(true);
          getUserPicture(res.data.cedula);
          getUserTeamData(res.data.idteam);
          updateUserRol(res.data.role);
        })
        .catch((error) => {
          console.log(error);
          updateAuthStatus(false);
        });
    } else {
      updateAuthStatus(false);
      navigate("/iniciarsesion");
    }
  };

  const getUserPicture = async (idUser) => {
    await axios
      .get(
        `https://tennis-open-league-api.vercel.app/v1/user/get-picture/${idUser}`
      )
      .then((res) => {
        updateUserPicture(res.data);
      })
      .catch(() => updateUserPicture(defaultUserPicture));
  };

  useEffect(() => {
    if (userInfo && userInfo.idteam) {
      getCalendarioByTeamData(userInfo.idteam);
    }
  }, [userInfo]);

  const getCalendarioByTeamData = async (idteam) => {
    const currentFase = getCurrentFase();
    await axios
      .get(
        `https://tennis-open-league-api.vercel.app/v1/fasesjornadas/getbyteamfase/${idteam}/${currentFase}`
      )
      .then((res) => {
        updateJornadasData(res.data);
      })
      .catch((error) => console.log(error));
  };
  const dashboardLinksByUser = getDashboardListByRole(userRol);

  return (
    <Box
      id="dashboard"
      sx={{
        backgroundColor: "#03438a",
        padding: { md: "40px 100px 30px 0", xs: "50px 0px 30px 0px" },
        width: { xs: "-webkit-fill-available", md: "100%" },
        height: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          paddingTop: "50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "10%",
            width: { md: "20%", xs: "100%" },
            justifyContent: "center",
            padding: "0 20px 0 50px",
            textAlign: { md: "left", xs: "center" },
            marginBottom: { md: 0, xs: "20px" },
          }}
        >
          <img
            src={logoTOLBlanco}
            alt="Tennis Open League"
            style={logoTOLStyles}
          />
          <Typography
            sx={{
              fontFamily: "Montserrat",
              color: "#ffffff",
              fontWeight: "bold",
              marginTop: "20px",
              marginBottom: "20px",
              fontSize: { md: "1.5rem", xs: "1rem" },
            }}
          >
            {`Bienvenid${userInfo && userInfo.sexo === "f" ? "a" : "o"}`}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              color: "#ffffff",
              fontWeight: "bold",
              marginBottom: "20px",
              fontSize: { md: "1.2rem", xs: "0.8rem" },
            }}
          >
            {userInfo && userInfo.name}
          </Typography>
          <Box
            sx={{ display: "flex", flexDirection: "column", color: "#ffffff" }}
          >
            {dashboardLinksByUser &&
              dashboardLinksByUser.map((item, key) => (
                <NavLink
                  key={key}
                  onClick={() => {
                    if (item.path === "/dashboard/cerrarsesion") {
                      sessionStorage.setItem("token", "");
                    }
                  }}
                  to={
                    item.path === "/dashboard/cerrarsesion"
                      ? "/iniciarsesion"
                      : item.path
                  }
                  target={
                    item.path ===
                    "https://drive.google.com/drive/folders/1sFxkZp_vTjpVKmK7tcDm1Y4kkcFszE9B"
                      ? "_blank"
                      : null
                  }
                  style={({ isActive, isPending }) => {
                    return {
                      color: isActive ? "#ffa436" : "inherit",
                      marginBottom: "5px",
                    };
                  }}
                  className={({ isActive, isPending }) => {
                    return isActive ? "active" : isPending ? "pending" : "";
                  }}
                >
                  {item.name}
                </NavLink>
              ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { md: "80%", xs: "100%" },
            textAlign: { xs: "center" },
            alignItems: "center",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
export default Dashboard;
