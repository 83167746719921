import { Box } from "@mui/material";
import "../App.css";
import Footer from "../components/Footer";
import AboutUs from "./AboutUs";
import Contacto from "./Contacto";
import Inicio from "./Inicio";
import Patrocinantes from "./Patrocinantes";
import Torneo from "./Torneo";

function MainPage() {
  return (
    <Box sx={{overflow:"hidden"}}>
      <Inicio />
      <AboutUs />
      <Torneo />
      <Patrocinantes />
      <Contacto />
      <Footer />
    </Box>
  );
}

export default MainPage;