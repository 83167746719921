import React from "react";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import {
  calendario,
  calendarioCategorias,
  categorias,
  longTextStyles,
} from "../helpers/constants";
import logoPurple from "../assets/Logo_TOL_Purple.png";
import logoTorneo2024 from "../assets/imagen_torneo_mobile.png";
import youCanDoItImg from "../assets/image_YouCanDoIt.png";

const Torneo = () => {
  const mainImgTorneo = window.innerWidth < 768 ? logoTorneo2024 : logoPurple;
  const mainImgTorneoStyles =
    window.innerWidth < 768 ? { width: "100%" } : { width: "30%" };
  return (
    <Box
      id="Torneo"
      sx={{
        backgroundColor: "#ffa436",
        paddingBottom: "30px",
        width: { xs: "-webkit-fill-available", md: "auto" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: { md: "70px 100px 30px 120px", xs: "50px 50px 30px 50px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "10%",
            width: "100%",
            justifyContent: "center",
            flexDirection: { md: "row", xs: "column" },
          }}
        >
          <Box
            sx={{
              width: { md: "50%", xs: "100%" },
              textAlign: { md: "left", xs: "center" },
              display: { md: "block", xs: "none" },
            }}
          >
            <img
              src={mainImgTorneo}
              alt="Tennis Open League"
              style={mainImgTorneoStyles}
            />
          </Box>
          <Box
            sx={{
              width: { md: "50%", xs: "100%" },
              display: "flex",
              justifyContent: { xs: "space-evenly" },
            }}
          >
            <List sx={{ width: { md: "50%", xs: "fit-content" } }}>
              <ListItem>
                <ListItemText
                  sx={{
                    color: "#ffffff",
                    display: "list-item",
                    letterSpacing: "1em",
                    paddingTop: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "MontserratBold",
                      fontSize: { md: "1.5rem", xs: "1rem" },
                    }}
                  >
                    {calendarioCategorias[0]}
                  </Typography>
                </ListItemText>
              </ListItem>
              {calendario.map((item, key) => (
                <ListItem key={key}>
                  <ListItemText
                    sx={{
                      color: "#ffffff",
                      display: "list-item",
                    }}
                    primary={item.primary}
                    primaryTypographyProps={{
                      fontSize: { md: "1rem", xs: "0.7rem" },
                      fontFamily: "MontserratBold",
                    }}
                    secondary={item.secundary}
                    secondaryTypographyProps={{
                      color: "#ffffff",
                      fontSize: { md: "0.875rem", xs: "0.5rem" },
                      fontFamily: "Montserrat",
                    }}
                  />
                </ListItem>
              ))}
            </List>
            <List
              sx={{
                width: { md: "50%", xs: "fit-content" },
                listStyleType: "disc",
                paddingLeft: { md: 0, xs: "15px" },
                paddingTop: { md: "11px", xs: "12px" },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "50px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "fit-content",
                  }}
                >
                  <ListItem
                    sx={{
                      color: "#ffffff",
                      letterSpacing: "0.25em",
                      paddingLeft: 0,
                      width: "fit-content",
                      fontSize: { md: "1.5rem", xs: "1rem" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "MontserratBold",
                        marginBottom: "20px !important",
                        fontSize: { md: "1.5rem", xs: "1rem" },
                      }}
                    >
                      {calendarioCategorias[2]}
                    </Typography>
                  </ListItem>

                  {categorias.map((item, key) => (
                    <ListItem
                      key={key}
                      sx={{
                        color: "#ffffff",
                        display: "list-item",
                        padding: 0,
                        width: "fit-content",
                      }}
                    >
                      {item}
                    </ListItem>
                  ))}
                </Box>
              </Box>
            </List>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "flex-end",
            alignSelf: "flex-start",
          }}
        >
          <Box
            sx={{
              paddingLeft: "30px",
              paddingRight: "20px",
              display: { md: "block", xs: "none" },
            }}
          >
            <img
              src={youCanDoItImg}
              alt="Tennis Open League"
              style={{
                width: "70%",
                marginBottom: "50px",
                marginLeft: "10%",
                marginTop: { md: "-50px", xs: "0" },
              }}
            />
            <Box sx={{ display: "none", justifyContent: "center" }}>
              <Typography
                sx={{
                  ...longTextStyles,
                  paddingTop: 0,
                  width: "fit-content",
                  fontWeight: "bold",
                  letterSpacing: "2em",
                  fontSize: { md: "1.5rem", xs: "1rem" },
                  color: "#03438a",
                }}
              >
                {calendarioCategorias[0]}
              </Typography>
              <Typography
                sx={{
                  ...longTextStyles,
                  paddingTop: 0,
                  width: "fit-content",
                  fontSize: { md: "1.5rem", xs: "1rem" },
                }}
              >
                {calendarioCategorias[1]}
              </Typography>
              <Typography
                sx={{
                  ...longTextStyles,
                  paddingTop: 0,
                  width: "fit-content",
                  fontWeight: "bold",
                  letterSpacing: "2em",
                  fontSize: { md: "1.2rem", xs: "0.8rem" },
                  marginLeft: "30px",
                  color: "#ef3248",
                }}
              >
                {calendarioCategorias[2]}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: { md: "none", xs: "block" } }}>
        <img
          src={youCanDoItImg}
          alt="Tennis Open League"
          style={{
            width: "100%",
            marginTop: { md: "-50px", xs: "0" },
          }}
        />
      </Box>
    </Box>
  );
};
export default Torneo;
