import React from "react";
import { Box } from "@mui/material";
import logoBlueFooterImg from "../assets/Logo_SoftBlue_Footer.png";

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#03438a",
        width: { xs: "-webkit-fill-available", md: "auto" },
        paddingLeft: { md: "100px", xs: 0 },
        paddingRight: { md: "100px", xs: 0 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          paddingTop: { md: "10px", xs: "0px" },
          paddingBottom: { md: "10px", xs: "0px" },
          width: "100%",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            width: { md: "25%", xs: "20%" },
            display: { xs: "flex" },
            alignItems: "flex-start",
            paddingTop: { xs: "15px" },
          }}
        >
          <img
            src={logoBlueFooterImg}
            alt="Tennis Open League"
            style={{
              width: window.innerWidth < 768 ? "60%" : "20%",
              marginLeft: window.innerWidth < 768 ? "20px" : "30px",
            }}
          />
        </Box>
        <Box sx={{ width: { md: "25%", xs: "20%" }, textAlign: "center" }}>
          <ul
            style={{
              textAlign: window.innerWidth < 768 ? "left" : "justify",
              color: "#ffffff",
              listStyle: "none",
              padding: window.innerWidth < 768 && 0,
            }}
          >
            <li
              style={{
                fontSize: window.innerWidth > 767 ? "12px" : "7px",
              }}
            >
              +58 414 - T - LEAGUE
              <br></br>
              +58 414 - 8 - 532483
            </li>
            <li
              style={{
                fontSize: window.innerWidth > 767 ? "12px" : "5.5px",
              }}
            >
              <button
                onClick={() => (window.location = "mailto:info@tennisopenleague.com")}
                style={{
                  fontFamily: "Montserrat",
                  color: "#ffffff",
                  border: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  paddingLeft: 0,
                }}
              >
                info@tennisopenleague.com
              </button>
            </li>
            <li
              style={{
                fontSize: window.innerWidth > 767 ? "12px" : "5.5px",
              }}
            >
              <a
                href="https://instagram.com/tennisopenleague/?hl=es"
                target="_blank"
                rel="noreferrer"
                style={{ fontFamily: "Montserrat", color: "#ffffff" }}
              >
                www.tennisopenleague.com
              </a>
            </li>
          </ul>
        </Box>
        <Box
          sx={{
            width: "25%",
            display: "flex",
            flexDirection: "column",
            paddingLeft: "15px",
          }}
        >
          <ul
            style={{
              textAlign: window.innerWidth < 768 ? "left" : "justify",
              color: "#ffffff",
              listStyle: "none",
              padding: window.innerWidth < 768 && 0,
            }}
          >
            <li
              style={{
                fontSize: window.innerWidth > 767 ? "12px" : "7.5px",
              }}
            >
              <a
                href="https://instagram.com/tennisopenleague/?hl=es"
                target="_blank"
                rel="noreferrer"
                style={{ fontFamily: "Montserrat", color: "#ffffff" }}
              >
                @tennisopenleague
              </a>
            </li>
            <li
              style={{
                fontSize: window.innerWidth > 767 ? "12px" : "7.5px",
              }}
            >
              Urb. Las Mercedes, Baruta
            </li>
            <li
              style={{
                fontSize: window.innerWidth > 767 ? "12px" : "7.5px",
              }}
            >
              Caracas - Venezuela<br></br>
            </li>
          </ul>
        </Box>
        <Box sx={{ width: "25%", display: "flex", flexDirection: "column" }}>
          <ul
            style={{
              textAlign: window.innerWidth < 768 ? "left" : "justify",
              color: "#ffffff",
              listStyle: "none",
              padding: window.innerWidth < 768 && "0 0 0 8px",
            }}
          >
            <li
              style={{
                fontSize: window.innerWidth > 767 ? "12px" : "7.5px",
                textAlign: "left",
              }}
            >
              {window.innerWidth < 768
                ? " 2024 ® Todos los derechos reservados"
                : "Todos los derechos reservados"}
            </li>
            <li
              style={{
                fontSize: window.innerWidth > 767 ? "12px" : "7.5px",
              }}
            >
              Desarrollado por:<br></br>
              Euricia Madrid
            </li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
};
export default Footer;
