import { Button } from "@mui/material";
import React from "react";

const GradientButton = ({
  text,
  onClick,
  typeButton = "orange",
  extraStyles,
}) => {
  const orangeStyles = `linear-gradient(to right, #ef3248, #ffa436)`;
  const blueStyles = `linear-gradient(to right, #0082e5, #03438a)`;
  const orangeStylesHover = `linear-gradient(to right, #ffa436, #ef3248)`;
  const blueStylesHover = `linear-gradient(to right, #03438a, #0082e5)`;
  const transparentButton = "transparent";
  const transparentButtonHover =
    typeButton === "transparentOrange"
      ? `linear-gradient(
    to right,
    rgba(0,0,0,0.001) 0%,
    #ffa436 50%,
    rgba(0,0,0,0.001) 100%
    )`
      : `linear-gradient(
      to right,
      rgba(0,0,0,0.001) 0%,
      #03438a 50%,
      rgba(0,0,0,0.001) 100%
      )`;
  return (
    <Button
      sx={{
        background:
          typeButton === "orange"
            ? orangeStyles
            : typeButton === "blue"
            ? blueStyles
            : transparentButton,
        padding: "10px 20px",
        borderRadius: "5px",
        cursor: "pointer",
        color: "#ffffff",
        textTransform: "capitalize",
        fontWeight: "bold",
        border:
          typeButton === "transparent" || typeButton === "transparentOrange"
            ? "2px solid #ffffff"
            : "none",
        "&:hover": {
          background:
            typeButton === "orange"
              ? orangeStylesHover
              : typeButton === "blue"
              ? blueStylesHover
              : typeButton === "transparent"
              ? transparentButtonHover
              : null,
        },
        ...extraStyles,
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default GradientButton;
