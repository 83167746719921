import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Dropdown } from "semantic-ui-react";
import { categoriesPlayer } from "../../helpers/dbConstants";
import "../usuarios/usuarios.css";
import { useNavigate, useParams } from "react-router-dom";
import { MainContext } from "../../helpers/MainContext";
import {
  convertDate,
  generateUserIdentificator,
  getPlayerCategoryInfo,
} from "../../helpers/commonFunctions";
import env from "react-dotenv";
import GradientButton from "../../components/GradientButton";
import axios from "axios";
import defaultUserPicture from "../../assets/foto_perfil_defecto.svg";

const data = [
  { name: "John Doe", age: 30, city: "New York" },
  { name: "Jane Doe", age: 25, city: "Los Angeles" },
];

const JugadorEquipo = () => {
  let { id: cedula } = useParams();
  //console.log(id);
  //const cedula = 17658647;
  const [userInfo, setUserInfo] = useState();
  const [userPicture, setUserPicture] = useState("");

  useEffect(() => {
    getUserPicture();
    getUserData();
  }, []);

  const getUserPicture = async (idUser) => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/user/get-picture/${cedula}`)
      .then((res) => {
        setUserPicture(res.data);
      })
      .catch((err) => setUserPicture(defaultUserPicture));
  };

  const getUserData = async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/user/getuserid/${cedula}`)
      .then((res) => {
        setUserInfo(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const { teamsInfo } = useContext(MainContext);
  const navigate = useNavigate();

  return (
    <Box
      p={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#03438a",
        minHeight: "100vh",
        fontFamily: "Montserrat",
        width: { md: "80%", xs: "80%" },
        alignItems: "start",
        borderRadius: "7px",
        border: "1px solid #ffffff",
        boxShadow: "4px 4px 4px #03435a",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          backgroundColor: "#03438a",
          width: "100%",
          borderRadius: "3px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            width: { md: "60%", xs: "100%" },
            textAlign: { md: "left", xs: "center" },
            placeContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: { md: "40%", xs: "100%" },
              alignItems: "center",
              paddingTop: { md: 0, xs: "20px" },
              paddingLeft: { md: "10px", xs: 0 },
            }}
          >
            <img
              src={userPicture}
              alt="Tennis Open League"
              style={{
                width: window.innerWidth < 768 ? "150px" : "150px",
                margin: "0 auto",
                height: "150px",
                borderRadius: "50%",
              }}
            />
          </Box>
          <Box
            p={2}
            sx={{ width: { md: "60%", xs: "100%" }, color: "#ffffff" }}
          >
            <Typography
              sx={{
                fontFamily: "MontserratBold",
                fontSize: { md: "1.25rem", xs: "1rem" },
                marginBottom: "15px",
              }}
            >
              {userInfo?.name}
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              {`Correo: ${userInfo?.email}`}
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              {`Celular: ${userInfo?.telefono}`}
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              {`Fecha de Nacimiento: ${userInfo?.dateofbirth}`}
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              {`Nro de Cedula: ${userInfo?.cedula}`}
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              {`ID #: ${generateUserIdentificator(
                userInfo?.cedula,
                userInfo?.id
              )}`}
            </Typography>
          </Box>
        </Box>
        <Box
          p={2}
          sx={{
            display: "flex",
            width: "40%",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <GradientButton
            text="VOLVER"
            typeButton="transparent"
            onClick={() => navigate("/dashboard/buscarequipo")}
            extraStyles={{ marginTop: "30px", padding: "5px" }}
          />
        </Box>
      </Box>
      <Box
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          color: "#ffffff",
          maxWidth: "-webkit-fill-available",
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ paddingBottom: "20px", fontFamily: "Montserrat" }}>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              {`Miembro desde: ${convertDate(userInfo?.fecha_creacion, "ES")}`}
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Club:{" "}
              {teamsInfo &&
                teamsInfo.find((elem) => elem.id === userInfo?.idteam)?.name}
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              {`Categoria: ${getPlayerCategoryInfo(userInfo?.idcategory)}`}
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>Ranking:</Typography>
          </Box>
          <Dropdown
            placeholder="Categoria"
            name="idcategory"
            className="perfil-usuario-category"
            labeled
            search
            selection
            disabled
            value={userInfo?.idcategory}
            options={categoriesPlayer}
            style={{ margin: "10px 0 30px 0" }}
          />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, width: "100%" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#0082e5" }}>
                  <TableCell
                    sx={{
                      fontFamily: "MontserratBold",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    FASE
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "MontserratBold",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    JOR.
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "MontserratBold",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    PARTNER
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "MontserratBold",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    RIVAL
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "MontserratBold",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    EQPO.
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "MontserratBold",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    SCORE
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "MontserratBold",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    RANKING
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{display:"none"}}>
                {data.map((row, key) => (
                  <TableRow
                    key={key}
                    sx={{
                      backgroundColor: key % 2 === 0 ? "#03438a" : "#0082e5",
                    }}
                  >
                    <TableCell
                      style={{
                        fontFamily: "Montserrat",
                        color: "#ffffff",
                        borderBottom: "none",
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Montserrat",
                        color: "#ffffff",
                        borderBottom: "none",
                      }}
                    >
                      {row.age}
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Montserrat",
                        color: "#ffffff",
                        borderBottom: "none",
                      }}
                    >
                      {row.city}
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Montserrat",
                        color: "#ffffff",
                        borderBottom: "none",
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Montserrat",
                        color: "#ffffff",
                        borderBottom: "none",
                      }}
                    >
                      {row.age}
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Montserrat",
                        color: "#ffffff",
                        borderBottom: "none",
                      }}
                    >
                      {row.city}
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Montserrat",
                        color: "#ffffff",
                        borderBottom: "none",
                      }}
                    >
                      {row.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};
export default JugadorEquipo;
