import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Dropdown } from "semantic-ui-react";
import axios from "axios";
import imgCalendario from "../../assets/dashboard/icono_vista_calendario.svg";
import GradientButton from "../../components/GradientButton";
import { useNavigate } from "react-router-dom";
import {
  getCategoryInfo,
  getCurrentFase,
  getDayOfWeek,
  semesters,
} from "../../helpers/commonFunctions";
import { MainContext } from "../../helpers/MainContext";

const ConsultarCalendario = () => {
  const [teamsData, setTeamsData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedFase, setSelectedFase] = useState("");
  const [selectedTeam, setSelectedTeam] = useState();
  const { userRol } = useContext(MainContext);
  const teamApi = useRef();
  const faseApi = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    getTeamsData();
    setSelectedFase(getCurrentFase());
    faseApi.current = getCurrentFase();
  }, []);

  const handleChange = (e, { name, value }) => {
    const filteredTeam = teamsData.find((item) => item.id === value);
    setSelectedTeam(filteredTeam.id);
    teamApi.current = filteredTeam.id;
    getCalendarioByTeamData();
  };

  const handleFaseChange = (e, { name, value }) => {
    setSelectedFase(value);
    faseApi.current = value;
    if (selectedTeam) {
      getCalendarioByTeamData();
    }
  };

  const getTeamsData = async () => {
    await axios
      .get(
        `
      https://tennis-open-league-api.vercel.app/v1/teams/getteams`
      )
      .then((res) => {
        if (userRol === 'adminn'){
          setTeamsData(res.data);
        }
        else {
          setTeamsData(res?.data?.filter((elem) => !elem?.name?.includes('Inactivo')));
        }
      })
      .catch((error) => console.log(error));
  };

  const getCalendarioByTeamData = async () => {
    await axios
      .get(
        `
      https://tennis-open-league-api.vercel.app/v1/fasesjornadas/getbyteamfase/${teamApi.current}/${faseApi.current}`
      )
      .then((res) => setTableData(res.data))
      .catch((error) => console.log(error));
  };

  const teamOptions =
    teamsData &&
    teamsData
      .map((team) => ({
        key: team.id,
        text: `${team.name} - ${getCategoryInfo(team.idcategory)}`,
        value: team.id,
      }))
      ?.filter((elem) => elem.text !== "Inactivo");

  const faseOptions = semesters.map((fase) => ({
    key: fase,
    text: fase,
    value: fase,
  }));

  const tableDataSorted = tableData && tableData?.sort((a, b) => a.semana - b.semana);

  return (
    <Box
      p={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#03438a",
        minHeight: "100vh",
        fontFamily: "Montserrat",
        width: { md: "80%", xs: "80%" },
        alignItems: "start",
        borderRadius: "7px",
        border: "1px solid #ffffff",
        boxShadow: "4px 4px 4px #03435a",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          backgroundColor: "#03438a",
          width: "100%",
          borderRadius: "3px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            width: { md: "50%", xs: "100%" },
            textAlign: { md: "left", xs: "center" },
            placeContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { md: "40%", xs: "100%" },
              alignItems: "center",
              paddingTop: { md: 0, xs: "20px" },
              paddingLeft: { md: "10px", xs: 0 },
            }}
          >
            <img
              src={imgCalendario}
              alt="Tennis Open League"
              style={{
                width: window.innerWidth < 768 ? "100px" : "150px",
                margin: "0 auto",
                height: "150px",
              }}
            />
          </Box>
          <Box
            p={2}
            sx={{ width: { md: "60%", xs: "100%" }, color: "#ffffff" }}
          >
            <Typography
              sx={{
                fontFamily: "MontserratBold",
                fontSize: { md: "1.25rem", xs: "1rem" },
                marginBottom: "15px",
              }}
            >
              Calendario
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
            {`Fase ${getCurrentFase()}`}
            </Typography>
          </Box>
        </Box>
        <Box
          p={2}
          sx={{
            display: "flex",
            width: { md: "50%", xs: "100%" },
            flexDirection: "column",
            alignItems: { md: "flex-end", xs: "center" },
            justifyContent: "flex-start",
          }}
        >
          <GradientButton
            text="VOLVER"
            typeButton="transparent"
            onClick={() => navigate("/dashboard")}
            extraStyles={{ marginTop: "30px", padding: "5px" }}
          />
        </Box>
      </Box>
      <Box
        p={{ md: 2, xs: 0 }}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          color: "#ffffff",
          maxWidth: "-webkit-fill-available",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100% !important",
            display: "flex",
            justifyContent: "space-around",
            paddingRight: "25px",
            paddingLeft: "10px",
            marginTop: "50px",
            flexDirection: { md: "row", xs: "column" },
          }}
        >
          <Typography>Consultar Equipo</Typography>
          <Dropdown
            placeholder="Equipo"
            name="name"
            labeled
            search
            selection
            options={teamOptions}
            onChange={handleChange}
            value={selectedTeam}
          />
          <Dropdown
            placeholder="Fase"
            name="fase"
            labeled
            search
            selection
            options={faseOptions}
            style={{ width: "10%" }}
            value={selectedFase}
            onChange={handleFaseChange}
          />
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, width: "100%" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#0082e5" }}>
              <TableCell
                sx={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                FASE 1
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                EQUIPOS
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                SEMANA
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                SEDE
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                DIA
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                HORA
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                FECHA
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableDataSorted &&
              tableDataSorted.map((row, key) => (
                <TableRow
                  key={key}
                  sx={{
                    backgroundColor: key % 2 === 0 ? "#03438a" : "#0082e5",
                  }}
                >
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row.idfase}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >{`${
                    teamsData.find((team) => team.id === row.idteamsede).name
                  } vs ${
                    teamsData.find((team) => team.id === row.idteamvisitante)
                      .name
                  }`}</TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row.semana}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {teamsData.find((team) => team.id === row.idteamsede).name}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {getDayOfWeek(row.fecha)}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row.hora}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row.fecha}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ConsultarCalendario;
