import React from "react";
import { Box, Typography } from "@mui/material";
import { patrocinantesText, patrocinantesTitle } from "../helpers/constants";
import letraOImg from "../assets/Letra_O_FondoAzul.png";
import patrocinantesImg from "../assets/imagen_patrocinantes.png";
import GradientButton from "../components/GradientButton";
import video1 from "../assets/RS_video.mov";
import Carousel from "../components/Carousel/Carousel";
import MovieClip from "./MovieClip";

const Patrocinantes = () => {
  const mainImgPatrocinantes =
    window.innerWidth < 768 ? patrocinantesImg : letraOImg;
  const mainImgPatrocinantesStyles =
    window.innerWidth < 768 ? { width: "100%" } : { width: "65%" };
  const logoRsMobile1 =
    window.innerWidth < 768
      ? { display: "none" }
      : { display: "block", width: "20%", marginBottom: "50px" };
  const logoRsMobile2 =
    window.innerWidth < 768
      ? { display: "block", width: "30%", marginBottom: "50px" }
      : { display: "none" };
  const buttonExtraStyles =
    window.innerWidth < 768
      ? { fontSize: "0.5rem", padding: "5px 15px" }
      : null;
  return (
    <Box
      id="Patrocinantes"
      sx={{
        backgroundColor: "#03438a",
        paddingBottom: "30px",
        width: { xs: "-webkit-fill-available", md: "auto" },
        paddingLeft: { md: "100px", xs: 0 },
        paddingRight: { md: "120px", xs: 0 },
        padding: { md: "0px 100px 30px 100px", xs: "0px 50px 30px 50px" },
        overflow: "hidden",
        display: "flex",
        placeContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "column", xs: "column" },
          paddingTop: "70px",
          paddingBottom: "50px",
          width: { md: "60%", xs: "80%" },
        }}
      >
        <Typography
          sx={{
            fontFamily: "MontserratBold",
            color: "#ffffff",
            marginBottom: "20px",
            fontSize: { md: "1.5rem", xs: "1rem" },
            textAlign: "center",
          }}
        >
          {patrocinantesTitle}
        </Typography>
        <Typography
          sx={{
            color: "#ffffff",
            textAlign: { md: "center", xs: "center" },
            marginBottom: { xs: "40px" },
            fontFamily: "Montserrat",
          }}
        >
          {patrocinantesText[0]}
        </Typography>
        <Box
          sx={{
            display: "flex",
            placeContent: "center",
            width: "100%",
          }}
        >
          <Carousel />
        </Box>
        <Box
          sx={{
            display: "flex",
            placeContent: "center",
          }}
        >
          <MovieClip />
        </Box>
      </Box>
    </Box>
  );
};
export default Patrocinantes;
