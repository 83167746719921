import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Form, Input, Button, Dropdown } from "semantic-ui-react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import "./tablaposiciones.css";
import { MainContext } from "../../helpers/MainContext";
import GradientButton from "../../components/GradientButton";
import Modal from "../../components/ModalPopUp/Modal";
import ModalMsg from "../../components/ModalPopUp/ModalMsg";
import { categories } from "../../helpers/dbConstants";
import { getCurrentFase } from "../../helpers/commonFunctions";

const CargarTablaPosiciones = () => {
  const { userInfo, userPicture } = useContext(MainContext);
  const [selectedCategory, setSelectedCategory] = useState();
  const [show, setShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({ type: "", text: "" });
  const [newUserPicture, setNewUserPicture] = useState();
  const [userPictureData, setUserPictureData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNewUserPicture(userPicture);
  }, [userPicture]);

  const navigate = useNavigate();

  const handleChange = (e, { name, value }) => {
    setSelectedCategory(value);
  };

  const handlePictureChange = (event) => {
    const [selectedFile] = event.target.files;
    if (!selectedFile) return;
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      setUserPictureData({
        name: selectedFile.name,
        type: selectedFile.type,
        size: selectedFile.size,
        data: reader.result,
        user_id: userInfo.cedula,
      });
      setNewUserPicture(reader.result);
    };
    reader.onerror = (error) => {
      console.error("Error reading image:", error);
    };
  };

  const handleSubmitPicture = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      fase: getCurrentFase(),
      idcategory: selectedCategory,
      image: userPictureData,
    };
    await axios
      .post(`https://tennis-open-league-api.vercel.app/v1/tablaposiciones/agregar`, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setModalInfo({
            type: "success",
            text: "Tabla de posiciones cargada correctamente!",
          });
          setShow(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setModalInfo({
          type: "error",
          text: "Hubo un error",
        });
      });
  };

  return (
    <Box
      id="modificar-usuarios"
      p={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#03438a",
        minHeight: "100vh",
        fontFamily: "Montserrat",
        width: { md: "80%", xs: "80%" },
        alignItems: "start",
        borderRadius: "7px",
        border: "1px solid #ffffff",
        boxShadow: "4px 4px 4px #03435a",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          backgroundColor: "#03438a",
          width: "100%",
          borderRadius: "3px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            width: { md: "50%", xs: "100%" },
            textAlign: { md: "left", xs: "center" },
            placeContent: "center",
          }}
        >
          <Box
            p={2}
            sx={{ width: { md: "60%", xs: "100%" }, color: "#ffffff" }}
          >
            <Typography
              sx={{
                fontFamily: "MontserratBold",
                fontSize: { md: "1.25rem", xs: "1rem" },
                marginBottom: "15px",
              }}
            >
              Tabla de posiciones
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Seleccione el archivo correspondiente a cada categoria
            </Typography>
          </Box>
        </Box>
        <Box
          p={2}
          sx={{
            display: "flex",
            width: "50%",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
          }}
        >
          <GradientButton
            text="VOLVER"
            typeButton="transparent"
            onClick={() => navigate("/dashboard")}
            extraStyles={{ marginTop: "30px", padding: "5px" }}
          />
        </Box>
      </Box>
      <Box
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          color: "#ffffff",
          maxWidth: "-webkit-fill-available",
          width: "100%",
        }}
      >
        <Box marginTop={8}>
          <Form
            onSubmit={handleSubmitPicture}
            className="contact-form-modificar-user"
            id="form-cargar-tabla-posiciones"
          >
            <Box display="flex" justifyContent="space-between">
              <Dropdown
                placeholder="Categoria"
                name="idcategory"
                labeled
                search
                selection
                options={categories}
                onChange={handleChange}
              />
              <Form.Field
                id="form-input-control-picture-modificar"
                control={Input}
                type="file"
                name="picture"
                placeholder="Foto de perfil"
                title=""
                accept=".jpg,.jpeg,.png;size=2MB"
                required
                onChange={handlePictureChange}
              />
            </Box>
            <Button
              type="submit"
              style={{
                backgroundColor: "#ffa436",
                color: "#ffffff",
                padding: "5px",
                marginBottom: "50px",
                marginTop: "50px",
              }}
              className={loading ? "spinner-button" : null}
              disabled={loading}
            >
              Subir Imagen
            </Button>
          </Form>
        </Box>
      </Box>
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <ModalMsg typeModal={modalInfo.type} textModal={modalInfo.text} />
      </Modal>
    </Box>
  );
};

export default CargarTablaPosiciones;
