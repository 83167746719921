import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { MainContext } from "../helpers/MainContext";

function ProtectedRoute({ children }) {
  const { isAuthenticated } = useContext(MainContext); // Access state from context
  const userToken = sessionStorage.getItem("token");

  if (!isAuthenticated) {
    if (userToken === "") {
      return <Navigate to="/iniciarsesion" />;
    }
    // Redirect to login
  }

  return children;
}

export default ProtectedRoute;
