import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Dropdown } from "semantic-ui-react";
import axios from "axios";
import imgBTablaPosiciones from "../../assets/dashboard/12_imagen_Posiciones.svg";
import GradientButton from "../../components/GradientButton";
import { useNavigate } from "react-router-dom";
import "./ranking.css";
import { categoriesSinMixto } from "../../helpers/dbConstants";

const VerRankingJugadores = () => {
  const [selectedCategory, setSelectedCategory] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e, { name, value }) => {
    setSelectedCategory(value);
  };

  const getTablaPosicion = useCallback(async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/rankingjugador/buscar/${selectedCategory}`)
      .then((res) => setCategoryData(res.data?.filter((elem) => !elem.team_name.includes('Inactivo'))))
      .catch((error) => {
        console.log(error);
      });
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedCategory) getTablaPosicion();
  }, [getTablaPosicion, selectedCategory]);

  let sortedData = [];
  if (categoryData.length > 0) {
    sortedData =
      categoryData && categoryData?.sort((a, b) => b.puntos - a.puntos);

    let currentRank = 1;
    let previousPuntos = sortedData && sortedData[0].puntos;

    sortedData &&
      sortedData.forEach((player, index) => {
        if (player.puntos !== previousPuntos) {
          currentRank = index + 1;
        }
        player.final = currentRank;
        previousPuntos = player.puntos;
      });
  }

  return (
    <Box
      p={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#03438a",
        minHeight: "100vh",
        fontFamily: "Montserrat",
        width: { md: "80%", xs: "80%" },
        alignItems: "start",
        borderRadius: "7px",
        border: "1px solid #ffffff",
        boxShadow: "4px 4px 4px #03435a",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          backgroundColor: "#03438a",
          width: "100%",
          borderRadius: "3px",
          marginTop: "50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            width: { md: "50%", xs: "100%" },
            textAlign: { md: "left", xs: "center" },
            placeContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { md: "40%", xs: "100%" },
              alignItems: "center",
              paddingTop: { md: 0, xs: "20px" },
              paddingLeft: { md: "10px", xs: 0 },
            }}
          >
            <img
              src={imgBTablaPosiciones}
              alt="Tennis Open League"
              style={{
                width: window.innerWidth < 768 ? "100px" : "150px",
                margin: "0 auto",
                height: "150px",
              }}
            />
          </Box>
          <Box
            p={2}
            sx={{ width: { md: "60%", xs: "100%" }, color: "#ffffff" }}
          >
            <Typography
              sx={{
                fontFamily: "MontserratBold",
                fontSize: { md: "1.25rem", xs: "1rem" },
                marginBottom: "15px",
              }}
            >
              Ranking Jugadores
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Selecciona la categoria que quieres consultar
            </Typography>
          </Box>
        </Box>
        <Box
          p={2}
          sx={{
            display: "flex",
            width: { md: "50%", xs: "100%" },
            flexDirection: "column",
            alignItems: { md: "flex-end", xs: "center" },
            justifyContent: "flex-start",
          }}
        >
          <GradientButton
            text="VOLVER"
            typeButton="transparent"
            onClick={() => navigate("/dashboard")}
            extraStyles={{ marginTop: "30px", padding: "5px" }}
          />
        </Box>
      </Box>
      <Box
        p={{ md: 2, xs: 0 }}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          color: "#ffffff",
          maxWidth: "-webkit-fill-available",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100% !important",
            display: "flex",
            justifyContent: "space-around",
            paddingRight: "25px",
            paddingLeft: "10px",
            marginTop: "50px",
            alignItems: "center",
          }}
        >
          <Typography>Consultar Categoria</Typography>
          <Dropdown
            placeholder="Categoria"
            name="idcategory"
            labeled
            search
            selection
            options={categoriesSinMixto}
            onChange={handleChange}
          />
        </Box>
      </Box>
      {categoryData && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, width: "100%" }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#0082e5" }}>
                <TableCell
                  style={{
                    fontFamily: "MontserratBold",
                    color: "#ffffff",
                    borderBottom: "none",
                  }}
                >
                  POSITION
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "MontserratBold",
                    color: "#ffffff",
                    borderBottom: "none",
                  }}
                >
                  CEDULA
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "MontserratBold",
                    color: "#ffffff",
                    borderBottom: "none",
                  }}
                >
                  NOMBRE
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "MontserratBold",
                    color: "#ffffff",
                    borderBottom: "none",
                  }}
                >
                  EQPO.
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "MontserratBold",
                    color: "#ffffff",
                    borderBottom: "none",
                  }}
                >
                  PUNTOS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row, key) => (
                <TableRow
                  key={key}
                  sx={{
                    backgroundColor: key % 2 === 0 ? "#03438a" : "#0082e5",
                  }}
                >
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row.final}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row.cedula}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row.team_name}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row.puntos}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default VerRankingJugadores;
