import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Dropdown } from "semantic-ui-react";
import imgNivelSuperior from "../../assets/dashboard/14_imagen_ReporteNS.svg";
import GradientButton from "../../components/GradientButton";
import { useNavigate } from "react-router-dom";
import "./nivelsuperior.css";
import { MainContext } from "../../helpers/MainContext";
import { getCurrentFase, getPlayerCategoryInfo, semesters } from "../../helpers/commonFunctions";

const VerNivelSuperior = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedFase, setSelectedFase] = useState('');
  const faseApi = useRef();
  const { teamsInfo } = useContext(MainContext);
  const navigate = useNavigate();

  const getNivelSuperiorData = useCallback(async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/getallnivelsuperior`)
      .then((res) => setTableData(res?.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    getNivelSuperiorData();
    setSelectedFase(getCurrentFase())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFaseChange = (e, { name, value }) =>{
    setSelectedFase(value);
    faseApi.current = value;
  }

  const faseOptions = semesters.map((fase) => ({
    key: fase,
    text: fase,
    value: fase
  }));

  const filteredTableData = selectedFase ? tableData?.filter((item) => item.idfase === selectedFase) : tableData?.filter((item) => item.idfase === getCurrentFase());

  return (
    <Box
      p={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#03438a",
        minHeight: "100vh",
        fontFamily: "Montserrat",
        width: { md: "80%", xs: "80%" },
        alignItems: "start",
        borderRadius: "7px",
        border: "1px solid #ffffff",
        boxShadow: "4px 4px 4px #03435a",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          backgroundColor: "#03438a",
          width: "100%",
          borderRadius: "3px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            width: { md: "50%", xs: "100%" },
            textAlign: { md: "left", xs: "center" },
            placeContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { md: "40%", xs: "100%" },
              alignItems: "center",
              paddingTop: { md: 0, xs: "20px" },
              paddingLeft: { md: "10px", xs: 0 },
            }}
          >
            <img
              src={imgNivelSuperior}
              alt="Tennis Open League"
              style={{
                width: window.innerWidth < 768 ? "100px" : "150px",
                margin: "0 auto",
                height: "150px",
              }}
            />
          </Box>
          <Box
            p={2}
            sx={{ width: { md: "60%", xs: "100%" }, color: "#ffffff" }}
          >
            <Typography
              sx={{
                fontFamily: "MontserratBold",
                fontSize: { md: "1.25rem", xs: "1rem" },
                marginBottom: "15px",
              }}
            >
              Nivel Superior
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Jugadores reportados con nivel superior
            </Typography>
          </Box>
        </Box>
        <Box
          p={2}
          sx={{
            display: "flex",
            width: { md: "50%", xs: "100%" },
            flexDirection: "column",
            alignItems: { md: "flex-end", xs: "center" },
            justifyContent: "flex-start",
          }}
        >
          <GradientButton
            text="VOLVER"
            typeButton="transparent"
            onClick={() => navigate("/dashboard")}
            extraStyles={{ marginTop: "30px", padding: "5px" }}
          />
        </Box>
      </Box>
      <Box
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          color: "#ffffff",
          maxWidth: "-webkit-fill-available",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100% !important",
            display: "flex",
            justifyContent: "space-around",
            paddingRight: "25px",
            paddingLeft: "10px",
            marginTop: "50px",
          }}
        >
          <Dropdown
            placeholder="Fase"
            name="fase"
            labeled
            search
            selection
            options={faseOptions}
            style={{width:'10%'}}
            value={selectedFase}
            onChange={handleFaseChange}
          />
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, width: "100%" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#0082e5" }}>
              <TableCell
                sx={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                FASE
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                CEDULA
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                N DE REPORTES
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                EQUIPO
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                CATEGORIA
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTableData &&
              filteredTableData
                .sort((a, b) => a.semana - b.semana)
                .map((row, key) => (
                  <TableRow
                    key={key}
                    sx={{
                      backgroundColor: key % 2 === 0 ? "#03438a" : "#0082e5",
                    }}
                  >
                    <TableCell
                      style={{
                        fontFamily: "Montserrat",
                        color: "#ffffff",
                        borderBottom: "none",
                      }}
                    >
                      {row.idfase}
                    </TableCell>
                    <TableCell
                      className="calendario-alineacion-row"
                      style={{
                        fontFamily: "Montserrat",
                        color: "#ffffff",
                        borderBottom: "none",
                      }}
                    >
                      {row?.cedula}
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Montserrat",
                        color: "#ffffff",
                        borderBottom: "none",
                      }}
                    >
                      {row?.count_nivel_superior}
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Montserrat",
                        color: "#ffffff",
                        borderBottom: "none",
                      }}
                    >
                      {
                        teamsInfo?.find((team) => team?.id === row?.idteam)
                          ?.name
                      }
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Montserrat",
                        color: "#ffffff",
                        borderBottom: "none",
                      }}
                    >
                      {getPlayerCategoryInfo(row?.idcategory)}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VerNivelSuperior;
