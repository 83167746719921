import React from "react";
import { styled } from '@mui/material/styles';
import { useScrollTrigger } from "@mui/material";
import Zoom from '@mui/material/Zoom';
/* const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
})); */

const Root = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

const ScrollTop = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#Inicio"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <Root>
        <div onClick={handleClick} role="presentation">
          {children}
        </div>
      </Root>
    </Zoom>
  );
};
export default ScrollTop;
