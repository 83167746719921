import React, { createContext, useState } from "react";
import defaultUserPicture from "../assets/foto_perfil_defecto.svg";

const MainContext = createContext({
  isAuthenticated: false,
  setIsAuthenticated: (newValue) => {},
  userInfo: {},
  setUserInfo: (newValue) => {},
  teamsInfo: {},
  setTeamsInfo: (newValue) => {},
});

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [userTeamInfo, setUserTeamInfo] = useState({});
  const [teamsInfo, setTeamsInfo] = useState([]);
  const [userPicture, setUserPicture] = useState(defaultUserPicture);
  const [userRol, setUserRol] = useState("");
  const [jornadasData, setJornadasData] = useState([]);

  const updateAuthStatus = (newValue) => {
    setIsAuthenticated(newValue);
  };
  const updateUserInfo = (newValue) => {
    setUserInfo(newValue);
  };
  const updateUserPicture = (newValue) => {
    setUserPicture(newValue);
  };
  const updateUserRol = (newValue) => {
    setUserRol(newValue);
  };
  const updateTeamsInfo = (newValue) => {
    setTeamsInfo(newValue);
  };
  const updateUserTeamInfo = (newValue) => {
    setUserTeamInfo(newValue);
  };
  const updateJornadasData = (newValue) => {
    setJornadasData(newValue);
  };
  return (
    <MainContext.Provider
      value={{
        isAuthenticated,
        updateAuthStatus,
        userInfo,
        updateUserInfo,
        userPicture,
        updateUserPicture,
        userRol,
        updateUserRol,
        teamsInfo,
        updateTeamsInfo,
        userTeamInfo,
        updateUserTeamInfo,
        jornadasData,
        updateJornadasData,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export { MainContext, AuthProvider };
