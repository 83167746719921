import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import imgResultados from "../../assets/dashboard/icono_vista_resultados.svg";
import GradientButton from "../../components/GradientButton";
import { useNavigate } from "react-router-dom";
import "./resultados.css";
import { MainContext } from "../../helpers/MainContext";
import { categories } from "../../helpers/dbConstants";
import { Dropdown } from "semantic-ui-react";
import { getCurrentFase, semesters } from "../../helpers/commonFunctions";
import Spinner from "../../components/Spinner";

const VerResultadosConsulta = () => {
  const [tableData, setTableData] = useState([]);
  const [category, setCategory] = useState("");
  const [selectedFase, setSelectedFase] = useState("");
  const categoryApi = useRef();
  const faseApi = useRef();
  const { teamsInfo } = useContext(MainContext);
  const navigate = useNavigate();

  const getResultsData = useCallback(async () => {
    if (categoryApi.current && faseApi.current) {
      await axios
        .get(
          `https://tennis-open-league-api.vercel.app/v1/fasesjornadas/getlistcategoryfase/${categoryApi.current}/${faseApi.current}`
        )
        .then((res) => setTableData(res?.data))
        .catch((error) => console.log(error));
    }
  }, []);

  useEffect(() => {
    const filtrosBusqueda = localStorage.getItem("filtrosBusqueda");
    const filtroBusquedaFase = localStorage.getItem("filtrosBusquedaFase");
    if (filtrosBusqueda !== "" && filtroBusquedaFase !== "") {
      setCategory(parseInt(filtrosBusqueda, 10));
      categoryApi.current = parseInt(filtrosBusqueda, 10);
      setSelectedFase(filtroBusquedaFase);
      faseApi.current = filtroBusquedaFase;
      getResultsData();
    } else {
      setSelectedFase(getCurrentFase());
      faseApi.current = getCurrentFase();
    }
  }, []);

  const handleFaseChange = (e, { name, value }) => {
    localStorage.setItem("filtrosBusquedaFase", value);
    setSelectedFase(value);
    faseApi.current = value;
    if (category) {
      getResultsData();
    }
  };

  const handleChange = (e, { name, value }) => {
    setCategory(value);
    categoryApi.current = value;
    getResultsData();
    localStorage.setItem("filtrosBusqueda", value);
  };

  const faseOptions = semesters.map((fase) => ({
    key: fase,
    text: fase,
    value: fase,
  }));

  return (
    <Box
      p={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#03438a",
        minHeight: "100vh",
        fontFamily: "Montserrat",
        width: { md: "80%", xs: "80%" },
        alignItems: "start",
        borderRadius: "7px",
        border: "1px solid #ffffff",
        boxShadow: "4px 4px 4px #03435a",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          backgroundColor: "#03438a",
          width: "100%",
          borderRadius: "3px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            width: { md: "50%", xs: "100%" },
            textAlign: { md: "left", xs: "center" },
            placeContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { md: "40%", xs: "100%" },
              alignItems: "center",
              paddingTop: { md: 0, xs: "20px" },
              paddingLeft: { md: "10px", xs: 0 },
            }}
          >
            <img
              src={imgResultados}
              alt="Tennis Open League"
              style={{
                width: window.innerWidth < 768 ? "100px" : "150px",
                margin: "0 auto",
                height: "150px",
              }}
            />
          </Box>
          <Box
            p={2}
            sx={{ width: { md: "60%", xs: "100%" }, color: "#ffffff" }}
          >
            <Typography
              sx={{
                fontFamily: "MontserratBold",
                fontSize: { md: "1.25rem", xs: "1rem" },
                marginBottom: "15px",
              }}
            >
              Resultados
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Planilla de Resultados
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              {`Fase ${getCurrentFase()}`}
            </Typography>
          </Box>
        </Box>
        <Box
          p={2}
          sx={{
            display: "flex",
            width: { md: "50%", xs: "100%" },
            flexDirection: "column",
            alignItems: { md: "flex-end", xs: "center" },
            justifyContent: "flex-start",
          }}
        >
          <GradientButton
            text="VOLVER"
            typeButton="transparent"
            onClick={() => navigate("/dashboard")}
            extraStyles={{ marginTop: "30px", padding: "5px" }}
          />
        </Box>
      </Box>
      <Box
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          color: "#ffffff",
          maxWidth: "-webkit-fill-available",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100% !important",
            display: "flex",
            justifyContent: "space-around",
            paddingRight: "25px",
            paddingLeft: "10px",
            marginTop: "50px",
            flexDirection: { md: "row", xs: "column" },
          }}
        >
          <Dropdown
            placeholder="Categoria"
            name="idcategory"
            labeled
            search
            selection
            value={category}
            options={categories}
            onChange={handleChange}
          />
          <Dropdown
            placeholder="Fase"
            name="fase"
            labeled
            search
            selection
            options={faseOptions}
            style={{ width: "10%" }}
            value={selectedFase}
            onChange={handleFaseChange}
          />
        </Box>
      </Box>
      {tableData ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, width: "100%" }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#0082e5" }}>
                <TableCell
                  sx={{
                    fontFamily: "MontserratBold",
                    color: "#ffffff",
                    borderBottom: "none",
                  }}
                >
                  FASE
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "MontserratBold",
                    color: "#ffffff",
                    borderBottom: "none",
                  }}
                >
                  SEMANA
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "MontserratBold",
                    color: "#ffffff",
                    borderBottom: "none",
                  }}
                >
                  EQUIPO SEDE
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "MontserratBold",
                    color: "#ffffff",
                    borderBottom: "none",
                  }}
                >
                  EQUIPO VISITANTE
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "MontserratBold",
                    color: "#ffffff",
                    borderBottom: "none",
                  }}
                >
                  FECHA
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "MontserratBold",
                    color: "#ffffff",
                    borderBottom: "none",
                  }}
                >
                  TIPO JORNADA
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData &&
                tableData
                  .sort((a, b) => a.semana - b.semana)
                  .map((row, key) => (
                    <TableRow
                      key={key}
                      sx={{
                        backgroundColor: key % 2 === 0 ? "#03438a" : "#0082e5",
                      }}
                      onClick={() => {
                        if (
                          row?.alineacionconfirmadasede &&
                          row?.alineacionconfirmadavisitante
                        ) {
                          navigate(
                            `/dashboard/consultarresultados/${row.idjornada}`
                          );
                        }
                      }}
                    >
                      <TableCell
                        style={{
                          fontFamily: "Montserrat",
                          color: "#ffffff",
                          borderBottom: "none",
                        }}
                      >
                        {row?.idfase}
                      </TableCell>
                      <TableCell
                        className="calendario-alineacion-row"
                        style={{
                          fontFamily: "Montserrat",
                          color: "#ffffff",
                          borderBottom: "none",
                        }}
                      >
                        {row?.semana}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "Montserrat",
                          color: "#ffffff",
                          borderBottom: "none",
                        }}
                      >
                        {
                          teamsInfo?.find(
                            (team) => team?.id === row?.idteamsede
                          )?.name
                        }
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "Montserrat",
                          color: "#ffffff",
                          borderBottom: "none",
                        }}
                      >
                        {
                          teamsInfo?.find(
                            (team) => team?.id === row?.idteamvisitante
                          )?.name
                        }
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "Montserrat",
                          color: "#ffffff",
                          borderBottom: "none",
                        }}
                      >
                        {row?.fecha}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "Montserrat",
                          color: "#ffffff",
                          borderBottom: "none",
                        }}
                      >
                        {row?.tipojornada}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : <Spinner />}
    </Box>
  );
};

export default VerResultadosConsulta;
