import React, { useState } from "react";
import { Box } from "@mui/material";
import { Form, Input, Button } from "semantic-ui-react";
import axios from "axios";
import logoBlueFooterImg from "../../assets/Logo_SoftBlue_Footer.png";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/ModalPopUp/Modal";
import ModalMsg from "../../components/ModalPopUp/ModalMsg";
import GradientButton from "../../components/GradientButton";
import env from "react-dotenv";

const RecuperarContrasena = () => {
  const [userData, setUserData] = useState({ email: "" });
  const [modalInfo, setModalInfo] = useState({ type: "", text: "" });
  const [show, setShow] = useState(false);

  const handleChange = (e, { name, value }) =>
    setUserData({ ...userData, [name]: value });

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(`https://tennis-open-league-api.vercel.app/v1/user/password-recovery`, userData)
      .then((res) => {
        if (res.status === 200) {
          setModalInfo({
            type: "success",
            text: "¡Contraseña enviada con éxito!",
          });
          setShow(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.request.status === 404) {
          setModalInfo({
            type: "error",
            text: "El correo electrónico no se encuentra registrado",
          });
          setShow(true);
        }
      });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#03438a",
        display: "flex",
        placeContent: "center",
        height: "100vh",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: {
            md: "30%",
            xs: "80%",
          },
          display: "flex",
          placeContent: "center",
          flexDirection: "column",
          border: "1px solid #ffffff",
          height: "fit-content",
          padding: { md: "50px", xs: "30px" },
          textAlign: "center",
          boxShadow: "5px 5px 5px #03435a",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <img
            src={logoBlueFooterImg}
            alt="Tennis Open League"
            style={{
              width: window.innerWidth < 768 ? "40%" : "30%",
              marginBottom: "30px",
            }}
          />
        </Box>
        <Box>
          <Form onSubmit={handleSubmit} className="recovery-password-form">
            <Form.Field
              id="form-input-control-email"
              control={Input}
              name="email"
              placeholder="Correo electronico"
              required
              onChange={handleChange}
            />
            <Button type="submit" color="orange">
              Recuperar Contraseña
            </Button>
          </Form>
        </Box>
      </Box>
      <GradientButton
        text="VOLVER"
        typeButton="transparent"
        extraStyles={{ marginTop: "30px", padding:"10px 13px" }}
        onClick={() => navigate("/iniciarsesion")}
      />
      <GradientButton
        text="HOME"
        typeButton="nohover"
        extraStyles={{
          marginTop: "30px",
          background: `linear-gradient(to right, #0082e5, #0082e5)`,
          border: "none",
        }}
        onClick={() => navigate("/")}
      />
      <Modal
        show={show}
        onClose={() => {
          if (modalInfo.type === "success") navigate("/iniciarsesion");
          setShow(false);
        }}
      >
        <ModalMsg typeModal={modalInfo.type} textModal={modalInfo.text} />
      </Modal>
    </Box>
  );
};

export default RecuperarContrasena;
