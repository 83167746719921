import React from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import {
  aboutUsPilares,
  aboutUsSlogan,
  aboutUsText,
  aboutUsTitle,
  longTextStyles,
  longTextStylesBold,
} from "../helpers/constants";
import workHardImg from "../assets/imagen_work_hard.png";

const AboutUs = () => {
  const workHardImgStyles =
    window.innerWidth < 768 ? { width: "100%" } : { width: "100%" };
  const mainImgAboutUs = workHardImg;
  return (
    <Box
      id="Quiénes Somos"
      sx={{
        backgroundColor: "#03438a",
        padding: { md: "0 100px 30px 0", xs: "50px 12px 30px 0px" },
        width: { xs: "-webkit-fill-available", md: "auto" },
        maxWidth: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          paddingTop: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "10%",
            width: { md: "60%", xs: "100%" },
            justifyContent: "center",
            marginBottom: "-30px",
          }}
        >
          <img
            src={mainImgAboutUs}
            alt="Tennis Open League"
            style={workHardImgStyles}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { md: "40%", xs: "100%" },
            justifyContent: "flex-end",
            alignSelf: "flex-start",
            marginTop: { xs: "50px" },
            textAlign: { xs: "center" },
            paddingLeft: { md: 0, xs: "35px" },
            paddingRight: { md: 0, xs: "23px" },
          }}
        >
          <Box
            sx={{
              paddingLeft: "30px",
              paddingRight: "20px",
              textAlign: { md: "left" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "MontserratBold",
                color: "#ffffff",
                order: 1,
                marginBottom: "20px",
                fontSize: { md: "1.5rem", xs: "1rem" },
              }}
            >
              {aboutUsTitle}
            </Typography>
            <Typography
              sx={{
                ...longTextStyles,
                paddingTop: 0,
                width: "90%",
                fontWeight: "bold",
              }}
            >
              {aboutUsText[0]}
            </Typography>
            <Typography
              sx={{
                ...longTextStyles,
                paddingTop: 0,
                width: "90%",
              }}
            >
              {aboutUsText[1]}
            </Typography>
            <Typography
              sx={{
                ...longTextStyles,
                paddingTop: 0,
                width: "90%",
              }}
            >
              {aboutUsText[2]}
            </Typography>
            <Typography
              sx={{
                ...longTextStyles,
                paddingTop: 0,
                width: "90%",
                fontWeight: "bold",
              }}
            >
              {aboutUsText[3]}
            </Typography>
            <List sx={{ listStyleType: "disc", fontFamily: "Montserrat" }}>
              {aboutUsPilares.map((pilar, key) => (
                <ListItem
                  key={key}
                  sx={{ color: "#ffffff", display: "list-item" }}
                >
                  {pilar}
                </ListItem>
              ))}
            </List>
            <Typography sx={{ ...longTextStylesBold, width: "100%" }}>
              {aboutUsSlogan}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default AboutUs;
