import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainPage from "./pages/MainPage";
import IniciarSesion from "./pages/usuarios/IniciarSesion";
import GestionarUsuarios from "./pages/usuarios/GestionarUsuarios";
import ModificarUsuario from "./pages/usuarios/ModificarUsuario";
import RecuperarContrasena from "./pages/usuarios/RecuperarContrasena";
import Dashboard from "./pages/dashboard/Dashboard";
import { AuthProvider } from "./helpers/MainContext";
import PerfilUsuario from "./pages/usuarios/PerfilUsuario";
import DashboardGrid from "./pages/dashboard/DashboardGrid";
import ProtectedRoute from "./components/ProtectedRoute";
import GestionarEquipos from "./pages/equipos/GestionarEquipos";
import BuscarEquipo from "./pages/equipos/BuscarEquipo";
import JugadorEquipo from "./pages/equipos/JugadorEquipo";
import ConsultarCalendario from "./pages/calendario/ConsultarCalendario";
import AgregarFaseJornada from "./pages/calendario/AgregarFaseJornada";
import VerCalendario from "./pages/alineacion/VerCalendario";
import AgregarAlineacion from "./pages/alineacion/AgregarAlineacion";
import VerResultados from "./pages/resultados/VerResultados";
import CargarResultados from "./pages/resultados/CargarResultados";
import Ranking from "./pages/ranking/Ranking";
import Organigrama from "./pages/organigrama/Organigrama";
import VerResultadosConsulta from "./pages/resultados/VerResultadosConsulta";
import ResultadosConsulta from "./pages/resultados/ResultadosConsulta";
import VerNivelSuperior from "./pages/nivel_superior/VerNivelSuperior";
import CargarTablaPosiciones from "./pages/tabla_posiciones/CargarTablaPosiciones";
import VerTablaPosiciones from "./pages/tabla_posiciones/VerTablaPosiciones";
import VerRankingJugadores from "./pages/ranking/RankingJugadores";
import Administracion from "./pages/administracion/Administracion";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/iniciarsesion",
    element: <IniciarSesion />,
  },
  {
    path: "/recuperarcontrasena",
    element: <RecuperarContrasena />,
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/dashboard",
        element: <DashboardGrid />,
      },
      {
        path: "/dashboard/profile",
        element: <PerfilUsuario />,
      },
      {
        path: "/dashboard/profile/edit",
        element: <ModificarUsuario />,
      },
      {
        path: "/dashboard/modificarusuarios",
        element: <GestionarUsuarios />,
      },
      {
        path: "/dashboard/modificarequipos",
        element: <GestionarEquipos />,
      },
      {
        path: "/dashboard/buscarequipo",
        element: <BuscarEquipo />,
      },
      {
        path: "/dashboard/buscarequipo/:id",
        element: <JugadorEquipo />,
      },
      {
        path: "/dashboard/calendario",
        element: <ConsultarCalendario />,
      },
      {
        path: "/dashboard/agregarfasejornada",
        element: <AgregarFaseJornada />,
      },
      {
        path: "/dashboard/alineacion",
        element: <VerCalendario />,
      },
      {
        path: "/dashboard/alineacion/agregar/:id",
        element: <AgregarAlineacion />,
      },
      {
        path: "/dashboard/resultados",
        element: <VerResultados />,
      },
      {
        path: "/dashboard/resultados/:id",
        element: <CargarResultados />,
      },
      {
        path: "/dashboard/consultarresultados",
        element: <VerResultadosConsulta />,
      },
      {
        path: "/dashboard/consultarresultados/:id",
        element: <ResultadosConsulta />,
      },
      {
        path: "/dashboard/ranking",
        element: <Ranking />,
      },
      {
        path: "/dashboard/organigrama",
        element: <Organigrama />,
      },
      {
        path: "/dashboard/nivelsuperior",
        element: <VerNivelSuperior />,
      },
      {
        path: "/dashboard/cargartablaposiciones",
        element: <CargarTablaPosiciones />,
      },
      {
        path: "/dashboard/vertablaposiciones",
        element: <VerTablaPosiciones />,
      },
      {
        path: "/dashboard/rankingjugadores",
        element: <VerRankingJugadores />,
      },
      {
        path: "/dashboard/administracion",
        element: <Administracion />,
      },
    ],
  },
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
