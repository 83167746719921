import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Button, Dropdown, Form, Input } from "semantic-ui-react";
import axios from "axios";
import imgCalendario from "../../assets/dashboard/icono_vista_calendario.svg";
import GradientButton from "../../components/GradientButton";
import { useNavigate } from "react-router-dom";
import { getCategoryInfo, getCurrentFase } from "../../helpers/commonFunctions";
import { MainContext } from "../../helpers/MainContext";
import Modal from "../../components/ModalPopUp/Modal";
import ModalMsg from "../../components/ModalPopUp/ModalMsg";
import "./fasesjornadas.css";
import { categories } from "../../helpers/dbConstants";

const AgregarFaseJornada = () => {
  const [faseJornada, setFaseJornada] = useState([]);
  const [show, setShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({ type: "", text: "" });
  const navigate = useNavigate();
  const { teamsInfo, userTeamInfo } = useContext(MainContext);

  const handleChange = (e, { name, value }) =>
    setFaseJornada({ ...faseJornada, [name]: value });

  const teamOptions =
    teamsInfo &&
    teamsInfo.map((team) => ({
      key: team.id,
      text: `${team.name} - ${getCategoryInfo(team.idcategory)}`,
      value: team.id,
    }));
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(
        `
      https://tennis-open-league-api.vercel.app/v1/fasesjornadas/create`,
       faseJornada
      )
      .then((res) => {
        if (res.status === 200) {
          setModalInfo({
            type: "success",
            text: "Jornada creada correctamente!",
          });
          setShow(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setModalInfo({
          type: "error",
          text: "Hubo un error al tratar de crear la fase/jornada",
        });
        setShow(true);
      });
  };

  /*   const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .put(`http://localhost:3001/v1/fasesjornadas/update`, {...faseJornada, idjornada:3})
      .then((res) => {
        if (res.status === 200) {
          setModalInfo({
            type: "success",
            text: "Jornada creada correctamente!",
          });
          setShow(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setModalInfo({
          type: "error",
          text: "Hubo un error al tratar de crear la fase/jornada",
        });
        setShow(true);
      });
  }; */

  return (
    <Box
      p={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#03438a",
        minHeight: "100vh",
        fontFamily: "Montserrat",
        width: { md: "80%", xs: "80%" },
        alignItems: "start",
        borderRadius: "7px",
        border: "1px solid #ffffff",
        boxShadow: "4px 4px 4px #03435a",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          backgroundColor: "#03438a",
          width: "100%",
          borderRadius: "3px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            width: { md: "50%", xs: "100%" },
            textAlign: { md: "left", xs: "center" },
            placeContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { md: "40%", xs: "100%" },
              alignItems: "center",
              paddingTop: { md: 0, xs: "20px" },
              paddingLeft: { md: "10px", xs: 0 },
            }}
          >
            <img
              src={imgCalendario}
              alt="Tennis Open League"
              style={{
                width: window.innerWidth < 768 ? "100px" : "150px",
                margin: "0 auto",
                height: "150px",
              }}
            />
          </Box>
          <Box
            p={2}
            sx={{ width: { md: "60%", xs: "100%" }, color: "#ffffff" }}
          >
            <Typography
              sx={{
                fontFamily: "MontserratBold",
                fontSize: { md: "1.25rem", xs: "1rem" },
                marginBottom: "15px",
              }}
            >
              Calendario
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
            {`Fase ${getCurrentFase()}`}
            </Typography>
          </Box>
        </Box>
        <Box
          p={2}
          sx={{
            display: "flex",
            width: "50%",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
          }}
        >
          <GradientButton
            text="VOLVER"
            typeButton="transparent"
            onClick={() => navigate("/dashboard")}
            extraStyles={{ marginTop: "30px", padding: "5px" }}
          />
        </Box>
      </Box>
      <Box
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          color: "#ffffff",
          maxWidth: "-webkit-fill-available",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100% !important",
            display: "flex",
            justifyContent: "space-around",
            paddingRight: "25px",
            paddingLeft: "10px",
            marginTop: "50px",
          }}
        >
          <Typography>Agregar Fase Jornada</Typography>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Form
          onSubmit={handleSubmit}
          className="contact-form-agregar-fase-jornada"
        >
          <Form.Field
            id="form-input-control-fase"
            control={Input}
            label="Fase"
            name="idfase"
            placeholder="Fase"
            required
            onChange={handleChange}
          />
          <Form.Field
            id="form-input-control-fecha"
            control={Input}
            label="Fecha"
            name="fecha"
            placeholder="Fecha"
            required
            type="date"
            onChange={handleChange}
          />
          <Dropdown
            placeholder="Equipo Sede"
            name="idteamsede"
            labeled
            search
            selection
            options={teamOptions}
            onChange={handleChange}
          />
          <Dropdown
            placeholder="Equipo Visitante"
            name="idteamvisitante"
            labeled
            search
            selection
            options={teamOptions}
            onChange={handleChange}
          />
          <Form.Field
            id="form-input-control-hora"
            control={Input}
            label="Hora"
            name="hora"
            placeholder="Hora"
            required
            type="time"
            onChange={handleChange}
          />
          <Form.Field
            id="form-input-control-semana"
            control={Input}
            label="semana"
            name="semana"
            placeholder="semana"
            required
            onChange={handleChange}
          />
          <Form.Field
            id="form-input-control-inicio"
            control={Input}
            label="Fecha inicio de semana"
            name="iniciosemana"
            placeholder="Fecha inicio de semana"
            type="date"
            required
            onChange={handleChange}
          />
          <Form.Field
            id="form-input-control-fin"
            control={Input}
            label="Fecha fin de semana"
            name="finsemana"
            placeholder="Fecha fin de semana"
            type="date"
            required
            onChange={handleChange}
          />
          <Form.Field
            id="form-input-control-fin"
            control={Input}
            label="Tipo de jornada"
            name="tipojornada"
            placeholder="Tipo de jornada"
            required
            onChange={handleChange}
          />
          <Dropdown
            placeholder="Categoria"
            name="idcategory"
            labeled
            search
            selection
            options={categories}
            onChange={handleChange}
          />
          <Box sx={{ width: "100% !important" }}>
            <Button
              type="submit"
              style={{
                backgroundColor: "#ffa436",
                border: "none",
                padding: "7px 12px",
                boxShadow: "3px 3px 3px #03435a",
                cursor: "pointer",
                borderRadius: "5px",
              }}
            >
              <p
                style={{
                  color: "#ffffff",
                }}
              >
                Listo
              </p>{" "}
            </Button>
          </Box>
        </Form>
      </Box>
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <ModalMsg typeModal={modalInfo.type} textModal={modalInfo.text} />
      </Modal>
    </Box>
  );
};

export default AgregarFaseJornada;
