import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GradientButton from "../../components/GradientButton";
import organigramaImg from "../../assets/dashboard/vista_web_organigrama.svg";
import organigramaImgMobile from "../../assets/dashboard/vista_webmobile_organigrama.svg";

const Organigrama = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: "#03438a",
        display: "flex",
        placeContent: "center",
        height: "100vh",
        alignItems: "center",
        width:"100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: {
            md: "80%",
            xs: "80%",
          },
          display: "flex",
          placeContent: "center",
          flexDirection: "column",
          border: "1px solid #ffffff",
          height: "fit-content",
          padding: { md: "50px", xs: "30px" },
          textAlign: "center",
          boxShadow: "5px 5px 5px #03435a",
        }}
      >
        <Box>
          <img
            src={
              window.innerWidth < 768 ? organigramaImgMobile : organigramaImg
            }
            alt="organigrama"
          />
        </Box>
      </Box>
      <GradientButton
        text="VOLVER"
        typeButton="transparent"
        extraStyles={{ marginTop: "30px", padding: "10px 13px" }}
        onClick={() => navigate("/dashboard")}
      />
    </Box>
  );
};

export default Organigrama;
