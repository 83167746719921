import onePerfil from "../assets/dashboard/01_imagen_MiPerfil.svg";
import twoRanking from "../assets/dashboard/02_imagen_Ranking.svg";
import threeCalendario from "../assets/dashboard/03_imagen_Calendario.svg";
import fourResultados from "../assets/dashboard/04_imagen_Resultados.svg";
import fiveModificarUsers from "../assets/dashboard/05_imagen_ModificarUsuarios.svg";
import sixModificarTeams from "../assets/dashboard/06_imagen_ModificarEquipos.svg";
import sevenReglamento from "../assets/dashboard/07_imagen_Reglamento.svg";
import eightAlineacion from "../assets/dashboard/08_imagen_Alineacion.svg";
import nineOrganigrama from "../assets/dashboard/09_imagen_Organigrama.svg";
import tenCerrarSesion from "../assets/dashboard/10_imagen_CerrarSesion.svg";
import imgBuscarEquipo from "../assets/dashboard/11_imagen_BuscarEquipo.svg";
import consultarResultado from "../assets/dashboard/13_imagen_Consulta.svg";
import tablaPosicionesImg from "../assets/dashboard/12_imagen_Posiciones.svg";
import nivelSuperiorImg from "../assets/dashboard/14_imagen_ReporteNS.svg";
import cargarTablaImg from "../assets/dashboard/16_imagen_Carga.svg";
import historialImg from "../assets/dashboard/15_imagen_Historial.svg";

export const dashboardImagesListAdmin = [
  { img: onePerfil, url: "profile" },
  { img: historialImg, url: "ranking" },
  { img: twoRanking, url: "rankingjugadores" },
  { img: threeCalendario, url: "/dashboard/calendario" },
  { img: fourResultados, url: "/dashboard/resultados" },
  { img: consultarResultado, url: "/dashboard/consultarresultados" },
  { img: cargarTablaImg, url: "/dashboard/cargartablaposiciones" },
  { img: tablaPosicionesImg, url: "/dashboard/vertablaposiciones" },
  { img: nivelSuperiorImg, url: "/dashboard/nivelsuperior" },
  { img: fiveModificarUsers, url: "/dashboard/modificarusuarios" },
  { img: sixModificarTeams, url: "/dashboard/modificarequipos" },
  { img: imgBuscarEquipo, url: "/dashboard/buscarequipo" },
  { img: sevenReglamento, url: "https://drive.google.com/drive/folders/1sFxkZp_vTjpVKmK7tcDm1Y4kkcFszE9B" },
  { img: eightAlineacion, url: "/dashboard/alineacion" },
  { img: nineOrganigrama, url: "/dashboard/organigrama" },
  { img: tenCerrarSesion, url: "/cerrarsesion" },
];

export const dashboardImagesListCapitan = [
  { img: onePerfil, url: "profile" },
  { img: twoRanking, url: "rankingjugadores" },
  { img: threeCalendario, url: "/dashboard/calendario" },
  { img: fourResultados, url: "/dashboard/resultados" },
  { img: consultarResultado, url: "/dashboard/consultarresultados" },
  { img: tablaPosicionesImg, url: "/dashboard/vertablaposiciones" },
  { img: imgBuscarEquipo, url: "/dashboard/buscarequipo" },
  { img: sevenReglamento, url: "https://drive.google.com/drive/folders/1sFxkZp_vTjpVKmK7tcDm1Y4kkcFszE9B" },
  { img: eightAlineacion, url: "/dashboard/alineacion" },
  { img: nineOrganigrama, url: "/dashboard/organigrama" },
  { img: tenCerrarSesion, url: "/cerrarsesion" },
];
export const dashboardImagesListStaff = [
  { img: onePerfil, url: "profile" },
  { img: twoRanking, url: "rankingjugadores" },
  { img: threeCalendario, url: "/dashboard/calendario" },
  { img: fourResultados, url: "/dashboard/resultados" },
  { img: consultarResultado, url: "/dashboard/consultarresultados" },
  { img: tablaPosicionesImg, url: "/dashboard/vertablaposiciones" },
  { img: fiveModificarUsers, url: "/dashboard/modificarusuarios" },
  { img: imgBuscarEquipo, url: "/dashboard/buscarequipo" },
  { img: sevenReglamento, url: "https://drive.google.com/drive/folders/1sFxkZp_vTjpVKmK7tcDm1Y4kkcFszE9B" },
  { img: nineOrganigrama, url: "/dashboard/organigrama" },
  { img: tenCerrarSesion, url: "/cerrarsesion" },
];

export const dashboardImagesListJugador = [
  { img: onePerfil, url: "profile" },
  { img: twoRanking, url: "rankingjugadores" },
  { img: threeCalendario, url: "/dashboard/calendario" },
  { img: consultarResultado, url: "/dashboard/consultarresultados" },
  { img: tablaPosicionesImg, url: "/dashboard/vertablaposiciones" },
  { img: imgBuscarEquipo, url: "/dashboard/buscarequipo" },
  { img: sevenReglamento, url: "https://drive.google.com/drive/folders/1sFxkZp_vTjpVKmK7tcDm1Y4kkcFszE9B" },
  { img: nineOrganigrama, url: "/dashboard/organigrama" },
  { img: tenCerrarSesion, url: "/cerrarsesion" },
];
