import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Dropdown } from "semantic-ui-react";
import axios from "axios";
import imgBuscarEquipos from "../../assets/dashboard/icono_vista_buscar-equipo.svg";
import GradientButton from "../../components/GradientButton";
import { useNavigate } from "react-router-dom";
import "./equipos.css";
import env from "react-dotenv";
import {
  generateUserIdentificator,
  getCategoryInfo,
  getPlayerCategoryInfo,
} from "../../helpers/commonFunctions";
import { MainContext } from "../../helpers/MainContext";

const BuscarEquipo = () => {
  const [teamsData, setTeamsData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const { userRol } = useContext(MainContext);

  useEffect(() => {
    getTeamsData();
  }, []);

  const handleChange = (e, { name, value }) => {
    const filteredTeam = teamsData.find((item) => item.id === value);
    getPlayerByTeamData(filteredTeam.id);
  };

  const getTeamsData = async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/teams/getteams`)
      .then((res) => {
        if (userRol === "admin") {
          setTeamsData(res.data);
        } else {
          setTeamsData(res?.data?.filter((elem) => !elem?.name.includes("Inactivo")));
        }
      })
      .catch((error) => console.log(error));
  };

  const getPlayerByTeamData = async (idteam) => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/user/getusersbyteam/${idteam}`)
      .then((res) => setTableData(res.data))
      .catch((error) => console.log(error));
  };

  const teamOptions =
    teamsData &&
    teamsData.map((team) => ({
      key: team.id,
      text: `${team.name} - ${getCategoryInfo(team.idcategory)}`,
      value: team.id,
    }));

  return (
    <Box
      p={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#03438a",
        minHeight: "100vh",
        fontFamily: "Montserrat",
        width: { md: "80%", xs: "80%" },
        alignItems: "start",
        borderRadius: "7px",
        border: "1px solid #ffffff",
        boxShadow: "4px 4px 4px #03435a",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          backgroundColor: "#03438a",
          width: "100%",
          borderRadius: "3px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            width: { md: "50%", xs: "100%" },
            textAlign: { md: "left", xs: "center" },
            placeContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { md: "40%", xs: "100%" },
              alignItems: "center",
              paddingTop: { md: 0, xs: "20px" },
              paddingLeft: { md: "10px", xs: 0 },
            }}
          >
            <img
              src={imgBuscarEquipos}
              alt="Tennis Open League"
              style={{
                width: window.innerWidth < 768 ? "100px" : "150px",
                margin: "0 auto",
                height: "150px",
              }}
            />
          </Box>
          <Box
            p={2}
            sx={{ width: { md: "60%", xs: "100%" }, color: "#ffffff" }}
          >
            <Typography
              sx={{
                fontFamily: "MontserratBold",
                fontSize: { md: "1.25rem", xs: "1rem" },
                marginBottom: "15px",
              }}
            >
              Buscar Equipo
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Selecciona el equipo que quieres consultar
            </Typography>
          </Box>
        </Box>
        <Box
          p={2}
          sx={{
            display: "flex",
            width: { md: "50%", xs: "100%" },
            flexDirection: "column",
            alignItems: { md: "flex-end", xs: "center" },
            justifyContent: "flex-start",
          }}
        >
          <GradientButton
            text="VOLVER"
            typeButton="transparent"
            onClick={() => navigate("/dashboard")}
            extraStyles={{ marginTop: "30px", padding: "5px" }}
          />
        </Box>
      </Box>
      <Box
        p={{ md: 2, xs: 0 }}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          color: "#ffffff",
          maxWidth: "-webkit-fill-available",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100% !important",
            display: "flex",
            justifyContent: "space-around",
            paddingRight: "25px",
            paddingLeft: "10px",
            marginTop: "50px",
          }}
        >
          <Typography>Consultar Equipo</Typography>
          <Dropdown
            placeholder="Equipo"
            name="name"
            labeled
            search
            selection
            options={teamOptions}
            onChange={handleChange}
          />
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, width: "100%" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#0082e5" }}>
              <TableCell
                sx={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                ID
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                NOMBRE
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                CÉDULA
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                CORREO
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                CAT.
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                ROL
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.map((row, key) => (
                <TableRow
                  key={key}
                  sx={{
                    backgroundColor: key % 2 === 0 ? "#03438a" : "#0082e5",
                  }}
                >
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {generateUserIdentificator(row?.cedula, row?.id)}
                  </TableCell>
                  <TableCell
                    className="buscar-equipo-tablecell-name"
                    onClick={() => {
                      navigate(`/dashboard/buscarequipo/${row.cedula}`);
                    }}
                  >
                    {row?.name}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row?.cedula}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row?.email}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {getPlayerCategoryInfo(row?.idcategory)}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row.role}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BuscarEquipo;
