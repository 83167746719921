import React from "react";
import { Box, Typography } from "@mui/material";
import { contactPageText } from "../helpers/constants";
import tennisPlayersImg from "../assets/jugadores_footer.png";
import tennisPlayersImgMobile from "../assets/fotos_contacto.png";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import "./contact.css";
import { Form, Input, TextArea, Button } from "semantic-ui-react";
const SERVICE_ID = "service_20iu53h";
const TEMPLATE_ID = "template_njvpxya";
const PUBLIC_KEY = "ahIqLY53euOXbXFa9";

const Contacto = () => {
  const contactListStyle =
    window.innerWidth < 768
      ? { textAlign: "center", color: "#ffffff", listStyle: "none", padding: 0 }
      : { textAlign: "justify", color: "#ffffff" };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY).then(
      (result) => {
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully",
        });
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: error.text,
        });
      }
    );
    e.target.reset();
  };

  return (
    <Box
      id="Contáctanos"
      sx={{
        backgroundColor: "#4d1182",
        width: { xs: "-webkit-fill-available", md: "auto" },
        overflow: "hidden",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          display: { md: "none", xs: "block" },
          textAlign: "center",
          paddingTop: "30px",
        }}
      >
        <Typography
          sx={{
            fontSize: { md: "1.5rem", xs: "1rem" },
            color: "#ffffff",
            fontFamily: "MontserratBold",
          }}
        >
          CONTÁCTANOS
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingTop: { md: "70px", xs: "30px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "10%",
            width: "100%",
            maxWidth: { md: "100%", xs: "fit-content" },
            justifyContent: { md: "flex-start", xs: "center" },
            flexDirection: { md: "row", xs: "column" },
            padding: { md: "0px 120px 0px 100px", xs: "0px 50px 30px 30px" },
          }}
        >
          <Box
            sx={{
              width: { md: "40%", xs: "100%" },
              textAlign: "center",
              paddingLeft: { md: "30px", xs: 0 },
              display: "flex",
              justifyContent: { md: "flex-start", xs: "space-around" },
            }}
          >
            <Box
              sx={{
                width: { md: "50%", xs: "100%" },
                textAlign: "-webkit-center",
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontSize: { md: "1.5rem", xs: "1rem" },
                  textAlign: { md: "left", xs: "center" },
                  marginBottom: "20px",
                  fontFamily: "MontserratBold",
                }}
              >
                {contactPageText[0]}
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  textAlign: { md: "left", xs: "center" },
                  marginBottom: "20px",
                  fontSize: { md: "16px", xs: "14px" },
                  fontFamily: "Montserrat",
                }}
              >
                {contactPageText[1]}
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  fontSize: { md: "20px", xs: "10px" },
                  fontWeight: "bold",
                  textAlign: { md: "left", xs: "center" },
                  marginBottom: "20px",
                  display: { md: "block", xs: "none" },
                  fontFamily: "Montserrat",
                }}
              >
                {contactPageText[2]}
              </Typography>
              <ul style={contactListStyle}>
                <li
                  style={{
                    marginBottom: "10px",
                    fontSize: window.innerWidth > 767 ? "16px" : "13px",
                  }}
                >
                  {window.innerWidth < 767 ? (
                    <p>
                      Teléfono Whatsapp:{" "}
                      <strong>+58 414 - T - LEAGUE (8 - 532483)</strong>
                    </p>
                  ) : (
                    <p>
                      Teléfono Whatsapp<br></br>
                      <strong>+58 414 - T - LEAGUE</strong>
                      <br></br>
                      <strong>+58 414 - 8 - 532483</strong>
                    </p>
                  )}
                </li>
                <li
                  style={{
                    marginBottom: "10px",
                    fontSize: window.innerWidth > 767 ? "16px" : "13px",
                  }}
                >
                  <button
                    onClick={() =>
                      (window.location = "mailto:info@tennisopenleague.com")
                    }
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      border: "none",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      paddingLeft: 0,
                      textAlign: "left",
                    }}
                  >
                    {window.innerWidth < 767 ? (
                      <p>
                        E-mail Address:{" "}
                        <strong>info@tennisopenleague.com</strong>
                      </p>
                    ) : (
                      <p>
                        E-mail Address<br></br>
                        <strong>info@tennisopenleague.com</strong>
                      </p>
                    )}
                  </button>
                </li>
                <li
                  style={{
                    fontSize: window.innerWidth > 767 ? "16px" : "13px",
                  }}
                >
                  {window.innerWidth < 767 ? (
                    <p>
                      Official Website:{" "}
                      <strong>www.tennisopenleague.com</strong>
                    </p>
                  ) : (
                    <p>
                      Official Website<br></br>
                      <strong>www.tennisopenleague.com</strong>
                    </p>
                  )}
                </li>
              </ul>
            </Box>
          </Box>
          <Box
            sx={{
              width: { md: "40%", xs: "100%" },
              display: "flex",
              flexDirection: "column",
              alignItems: { md: "flex-start", xs: "center" },
              marginTop: { md: 0, xs: "30px" },
            }}
          >
            <Form onSubmit={handleSubmit} className="contact-form">
              <Form.Field
                id="form-input-control-last-name"
                control={Input}
                label="Tu nombre"
                name="from_name"
                placeholder="Tu nombre"
                required
              />
              <Form.Field
                id="form-input-control-email"
                control={Input}
                label="Tu correo electronico"
                name="from_email"
                placeholder="Tu correo electronico"
                required
              />
              <Form.Field
                id="form-textarea-control-opinion"
                control={TextArea}
                label="Tu mensaje"
                name="message"
                placeholder="Tu mensaje"
                required
              />
              <Button type="submit" color="blue">
                Enviar Mensaje
              </Button>
            </Form>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "flex-end",
            alignSelf: "flex-start",
            marginTop: { md: 0, xs: "-100px" },
          }}
        >
          <Box sx={{ marginBottom: { md: "-5px" } }}>
            <img
              src={
                window.innerWidth < 768
                  ? tennisPlayersImgMobile
                  : tennisPlayersImg
              }
              alt="Tennis Open League"
              style={{
                width: "100%",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Contacto;
