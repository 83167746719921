import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Form, Input, Button, Dropdown } from "semantic-ui-react";
import axios from "axios";
import { categories } from "../../helpers/dbConstants";
import imgGestionarEquipos from "../../assets/dashboard/icono_vista_gestionar-equipos.svg";
import Modal from "../../components/ModalPopUp/Modal";
import ModalMsg from "../../components/ModalPopUp/ModalMsg";
import GradientButton from "../../components/GradientButton";
import { useNavigate } from "react-router-dom";
import "./equipos.css";
import {
  generateUserIdentificator,
  getCategoryInfo,
} from "../../helpers/commonFunctions";

const GestionarEquipos = () => {
  const [teamData, setTeamData] = useState({});
  const [teamNewData, setTeamNewData] = useState({});
  const [show, setShow] = useState(false);
  const [teamsData, setTeamsData] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const [isNewTeam, setIsNewTeam] = useState(false);
  const [isOldTeam, setIsOldTeam] = useState(false);
  const [modalInfo, setModalInfo] = useState({ type: "", text: "" });
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getTeamsData();
  }, []);

  const handleChange = (e, { name, value }) => {
    if (isNewTeam) {
      setTeamNewData({ ...teamNewData, [name]: value });
    } else if (isOldTeam) {
      setTeamData({ ...teamData, [name]: value });
    } else {
      const filteredTeam = teamsData.find((item) => item.id === value);
      setTeamData(filteredTeam);
      getPlayerByTeamData(filteredTeam.id);
    }
  };

  const getTeamsData = async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/teams/getteams`)
      .then((res) => setTeamsData(res.data))
      .catch((error) => console.log(error));
  };

  const getPlayerByTeamData = async (idteam) => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/user/getusersbyteam/${idteam}`)
      .then((res) => setTableData(res.data))
      .catch((error) => console.log(error));
  };

  const teamOptions =
    teamsData &&
    teamsData.map((team) => ({
      key: team.id,
      text: `${team.name} - ${getCategoryInfo(team.idcategory)}`,
      value: team.id,
    }));

  const handleAgregarEquipo = async (e) => {
    const teamPayload = {
      name: teamNewData.name,
      idCategory: teamNewData.idcategory,
    };
    await axios
      .post(`https://tennis-open-league-api.vercel.app/v1/teams/create`, teamPayload)
      .then((res) => {
        if (res.status === 200) {
          setModalInfo({
            type: "success",
            text: "Equipo creado correctamente!",
          });
          setShow(true);
          getTeamsData();
          setTeamNewData({});
        }
      })
      .catch((error) => {
        console.log(error);
        setModalInfo({
          type: "error",
          text: "Hubo un error al tratar de crear el equipo",
        });
        setShow(true);
      });
  };

  const handleModificarEquipo = async () => {
    const teamPayload = {
      id: teamData.id,
      name: teamData.name,
      idCategory: teamData.idcategory,
    };
    await axios
      .put(`https://tennis-open-league-api.vercel.app/v1/teams/update`, teamPayload)
      .then((res) => {
        if (res.status === 200) {
          setModalInfo({
            type: "success",
            text: "Datos modificados correctamente!",
          });
          setShow(true);
          getTeamsData();
        }
      })
      .catch((error) => {
        console.log(error);
        setModalInfo({
          type: "error",
          text: "Hubo un error al tratar de modificar los datos",
        });
        setShow(true);
      });
  };

  return (
    <Box
      p={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#03438a",
        minHeight: "100vh",
        fontFamily: "Montserrat",
        width: { md: "80%", xs: "80%" },
        alignItems: "start",
        borderRadius: "7px",
        border: "1px solid #ffffff",
        boxShadow: "4px 4px 4px #03435a",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          backgroundColor: "#03438a",
          width: "100%",
          borderRadius: "3px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            width: { md: "50%", xs: "100%" },
            textAlign: { md: "left", xs: "center" },
            placeContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { md: "40%", xs: "100%" },
              alignItems: "center",
              paddingTop: { md: 0, xs: "20px" },
              paddingLeft: { md: "10px", xs: 0 },
            }}
          >
            <img
              src={imgGestionarEquipos}
              alt="Tennis Open League"
              style={{
                width: window.innerWidth < 768 ? "100px" : "150px",
                margin: "0 auto",
                height: "150px",
              }}
            />
          </Box>
          <Box
            p={2}
            sx={{ width: { md: "60%", xs: "100%" }, color: "#ffffff" }}
          >
            <Typography
              sx={{
                fontFamily: "MontserratBold",
                fontSize: { md: "1.25rem", xs: "1rem" },
                marginBottom: "15px",
              }}
            >
              Gestionar Equipos
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Escriba los campos necesarios para agregar o editar equipos
            </Typography>
          </Box>
        </Box>
        <Box
          p={2}
          sx={{
            display: "flex",
            width: "50%",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
          }}
        >
          <GradientButton
            text="VOLVER"
            typeButton="transparent"
            onClick={() => navigate("/dashboard")}
            extraStyles={{ marginTop: "30px", padding: "5px" }}
          />
        </Box>
      </Box>
      <Box
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          color: "#ffffff",
          maxWidth: "-webkit-fill-available",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100% !important",
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "25px",
            paddingLeft: "10px",
            marginTop: "50px",
          }}
        >
          <Typography>Consultar Equipo</Typography>
          <Dropdown
            placeholder="Equipo"
            name="name"
            labeled
            search
            selection
            disabled={canEdit}
            options={teamOptions}
            onChange={handleChange}
          />
          <Button
            type="submit"
            style={{
              backgroundColor: "#ffa436",
              border: "none",
              padding: "10px",
              boxShadow: "3px 3px 3px #03435a",
              cursor: "pointer",
              borderRadius: "5px",
              height: "fit-content",
            }}
            disabled={isOldTeam}
            onClick={() => {
              setIsOldTeam(false);
              if (canEdit && isNewTeam) {
                setCanEdit(false);
                setIsNewTeam(false);
                setTeamNewData({});
              } else {
                setCanEdit(true);
                setIsNewTeam(true);
                setTeamData({});
              }
            }}
          >
            <p
              style={{
                color: "#ffffff",
              }}
            >
              {canEdit && isNewTeam ? "Cancelar" : "Agregar"}
            </p>{" "}
          </Button>
          <Button
            type="submit"
            disabled={isNewTeam}
            style={{
              backgroundColor: "#ffa436",
              border: "none",
              padding: "10px",
              boxShadow: "3px 3px 3px #03435a",
              cursor: "pointer",
              borderRadius: "5px",
              marginRight: "15px",
              marginLeft: "15px",
              height: "fit-content",
            }}
            onClick={() => {
              if (teamData && Object.keys(teamData).length > 0) {
                setIsNewTeam(false);
                if (canEdit && isOldTeam) {
                  setCanEdit(false);
                  setIsOldTeam(false);
                } else {
                  setCanEdit(true);
                  setIsOldTeam(true);
                }
              }
            }}
          >
            <p
              style={{
                color: "#ffffff",
              }}
            >
              {canEdit && isOldTeam ? "Cancelar" : "Editar"}
            </p>{" "}
          </Button>
          <Button
            type="submit"
            style={{
              backgroundColor: "#ffa436",
              border: "none",
              padding: "10px",
              boxShadow: "3px 3px 3px #03435a",
              cursor: "pointer",
              borderRadius: "5px",
              height: "fit-content",
            }}
            onClick={(e) => {
              e.preventDefault();
              if (isNewTeam && teamNewData) {
                handleAgregarEquipo();
                setCanEdit(false);
                setIsNewTeam(false);
              }
              if (isOldTeam && teamData) {
                handleModificarEquipo();
                setCanEdit(false);
                setIsOldTeam(false);
              }
            }}
          >
            <p
              style={{
                color: "#ffffff",
              }}
            >
              Guardar
            </p>{" "}
          </Button>
        </Box>
        <Box>
          <Form
            id="contact-form-gestionar-equipos"
            className="contact-form-gestionar-equipos"
          >
            <Form.Field
              id="form-input-control-name"
              control={Input}
              label="Nombre Equipo"
              name="name"
              disabled={!canEdit}
              value={
                teamData && teamData.name
                  ? teamData.name
                  : teamNewData && teamNewData.name
                  ? teamNewData.name
                  : ""
              }
              required
              onChange={handleChange}
            />{" "}
            <Dropdown
              id="select-category-gestionar-usuarios"
              placeholder="Categoria"
              name="idcategory"
              labeled
              search
              selection
              disabled={!canEdit}
              value={
                teamData && teamData.idcategory
                  ? teamData.idcategory
                  : teamNewData && teamNewData.idcategory
                  ? teamNewData.idcategory
                  : ""
              }
              options={categories}
              onChange={handleChange}
            />
          </Form>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, width: "100%" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#0082e5" }}>
              <TableCell
                sx={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                ID
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                NOMBRE
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                CÉDULA
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                CORREO
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                CAT.
              </TableCell>
              <TableCell
                style={{
                  fontFamily: "MontserratBold",
                  color: "#ffffff",
                  borderBottom: "none",
                }}
              >
                ROL
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.map((row, key) => (
                <TableRow
                  key={key}
                  sx={{
                    backgroundColor: key % 2 === 0 ? "#03438a" : "#0082e5",
                  }}
                >
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {generateUserIdentificator(row?.cedula, row?.id)}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row?.name}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row?.cedula}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row?.email}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {getCategoryInfo(row?.idcategory)}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat",
                      color: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    {row.role}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <ModalMsg typeModal={modalInfo.type} textModal={modalInfo.text} />
      </Modal>
    </Box>
  );
};

export default GestionarEquipos;
