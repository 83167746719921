import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Form, Input, Button } from "semantic-ui-react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import "./usuarios.css";
import { MainContext } from "../../helpers/MainContext";
import GradientButton from "../../components/GradientButton";
import Modal from "../../components/ModalPopUp/Modal";
import ModalMsg from "../../components/ModalPopUp/ModalMsg";
import { convertDate } from "../../helpers/commonFunctions";

const ModificarUsuario = () => {
  const { userInfo, userPicture, updateUserPicture } = useContext(MainContext);
  const [userData, setUserData] = useState();
  const [show, setShow] = useState(false);
  const [teamsData, setTeamsData] = useState([]);
  const [modalInfo, setModalInfo] = useState({ type: "", text: "" });
  const [newUserPicture, setNewUserPicture] = useState(userPicture);
  const [userPictureData, setUserPictureData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTeamsData();
  }, []);

  useEffect(() => {
    setUserData({
      ...userInfo,
      password: "",
    });
  }, [userInfo]);

  useEffect(() => {
    setNewUserPicture(userPicture);
  }, [userPicture]);

  const navigate = useNavigate();
  const getTeamsData = async () => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/teams/getteams`)
      .then((res) => setTeamsData([res.data[0]]))
      .catch((error) => console.log(error));
  };

  const handleChange = (e, { name, value }) => {
    setUserData({ ...userData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userData?.password !=="" && userData?.idcategory !==null && userData?.idteam !== null) {
      // ELIMINAR OPERADORES INNECESARIOS. EN ESTA PANTALLA EL USUARIO NO PODRA EDITAR TODOS LOS CAMPOS
      const userPayload = {
        name: userData.name || userInfo.name,
        email: userData.email || userInfo.email,
        password: userData.password,
        dateOfBirth: userData.dateofbirth || userInfo.dateofbirth,
        cedula: userData.cedula || userInfo.cedula,
        telefono: userData.telefono || userInfo.telefono,
        persona_contacto:
          userData.persona_contacto || userInfo.persona_contacto,
        tlf_persona_contacto:
          userData.tlf_persona_contacto || userInfo.tlf_persona_contacto,
      };
      await axios
        .put(
          `https://tennis-open-league-api.vercel.app/v1/user/updateUserData`,
          userPayload
        )
        .then((res) => {
          if (res.status === 200) {
            setModalInfo({
              type: "success",
              text: "Datos modificados correctamente!",
            });
            setShow(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setModalInfo({
            type: "error",
            text: "Hubo un error al tratar de modificar los datos",
          });
          setShow(true);
        });
    }
  };

  const handlePictureChange = (event) => {
    const [selectedFile] = event.target.files;
    if (!selectedFile) return;
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      setUserPictureData({
        name: selectedFile.name,
        type: selectedFile.type,
        size: selectedFile.size,
        data: reader.result,
        user_id: userInfo.cedula,
      });
      setNewUserPicture(reader.result);
    };
    reader.onerror = (error) => {
      console.error("Error reading image:", error);
    };
  };

  const handleSubmitPicture = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post(
        `https://tennis-open-league-api.vercel.app/v1/user/upload-picture`,
        userPictureData
      )
      .then((res) => {
        if (res.status === 200) {
          updateUserPicture(newUserPicture);
          setLoading(false);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Box
      id="modificar-usuarios"
      p={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#03438a",
        minHeight: "100vh",
        fontFamily: "Montserrat",
        width: { md: "80%", xs: "80%" },
        alignItems: "start",
        borderRadius: "7px",
        border: "1px solid #ffffff",
        boxShadow: "4px 4px 4px #03435a",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          backgroundColor: "#03438a",
          width: "100%",
          borderRadius: "3px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            width: { md: "50%", xs: "100%" },
            textAlign: { md: "left", xs: "center" },
            placeContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { md: "40%", xs: "100%" },
              alignItems: "center",
              paddingTop: { md: 0, xs: "20px" },
              paddingLeft: { md: "10px", xs: 0 },
            }}
          >
            <img
              src={newUserPicture}
              alt="Tennis Open League"
              style={{
                width: window.innerWidth < 768 ? "150px" : "150px",
                margin: "0 auto",
                height: "150px",
                borderRadius: "50%",
              }}
            />
            <Form
              onSubmit={handleSubmitPicture}
              className="contact-form-modificar-user"
              id="contact-form-modificar-user"
            >
              <Form.Field
                id="form-input-control-picture-modificar"
                control={Input}
                type="file"
                name="picture"
                placeholder="Foto de perfil"
                title=""
                accept=".jpg,.jpeg,.png;size=2MB"
                required
                onChange={handlePictureChange}
              />
              <Button
                type="submit"
                style={{
                  backgroundColor: "#ffa436",
                  color: "#ffffff",
                  padding: "5px",
                  marginBottom: "50px",
                }}
                className={loading ? "spinner-button" : null}
                disabled={loading}
              >
                Subir Foto
              </Button>
            </Form>
          </Box>
          <Box
            p={2}
            sx={{ width: { md: "60%", xs: "100%" }, color: "#ffffff" }}
          >
            <Typography
              sx={{
                fontFamily: "MontserratBold",
                fontSize: { md: "1.25rem", xs: "1rem" },
                marginBottom: "15px",
              }}
            >
              Editar mi perfil
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Escriba los campos necesarios para su actualizaión
            </Typography>
          </Box>
        </Box>
        <Box
          p={2}
          sx={{
            display: "flex",
            width: "50%",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
          }}
        >
          <GradientButton
            text="VOLVER"
            typeButton="transparent"
            onClick={() => navigate("/dashboard/profile")}
            extraStyles={{ marginTop: "30px", padding: "5px" }}
          />
        </Box>
      </Box>
      <Box
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          color: "#ffffff",
          maxWidth: "-webkit-fill-available",
          width: "100%",
        }}
      >
        <Box>
          <Form
            onSubmit={handleSubmit}
            className="contact-form-modificar-perfil"
          >
            <Form.Field
              id="form-input-control-name"
              control={Input}
              label="Nombre completo"
              name="name"
              value={userData && userData.name}
              placeholder="Nombre de usuario"
              required
              onChange={handleChange}
            />{" "}
            <Form.Field
              id="form-input-control-password"
              control={Input}
              type="password"
              label="Contraseña"
              name="password"
              placeholder="Contraseña"
              value={userData?.password}
              onChange={handleChange}
              required
            />
            <Form.Field
              id="form-input-control-email"
              control={Input}
              label="Correo electronico"
              name="email"
              placeholder="Correo electronico"
              value={userData && userData.email}
              required
              onChange={handleChange}
            />
            <Form.Field
              id="form-input-control-fecha'nacimiento"
              control={Input}
              label="Fecha de nacimiento"
              name="dateofbirth"
              value={userData?.dateofbirth}
              placeholder="Fecha de nacimiento"
              required
              type="date"
              onChange={handleChange}
            />
            <Form.Field
              id="form-input-control-telefono"
              control={Input}
              label="Teléfono"
              name="telefono"
              value={userData && userData.telefono}
              placeholder="Telefono principal"
              onChange={handleChange}
            />
            <Form.Field
              id="form-input-control-persona-contacto"
              control={Input}
              label="Persona de Contacto"
              name="persona_contacto"
              value={userData && userData.persona_contacto}
              placeholder="Persona de contacto"
              onChange={handleChange}
            />
            <Form.Field
              id="form-input-control-telefono-persona-contacto"
              control={Input}
              label="Teléfono Persona de Contacto"
              name="tlf_persona_contacto"
              value={userData && userData.tlf_persona_contacto}
              placeholder="Telefono persona de contacto"
              onChange={handleChange}
            />
            <Box sx={{ width: "100% !important" }}>
              <Button
                type="submit"
                style={{
                  backgroundColor: "#ffa436",
                  border: "none",
                  padding: "7px 12px",
                  boxShadow: "3px 3px 3px #03435a",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
              >
                <p
                  style={{
                    color: "#ffffff",
                  }}
                >
                  Listo
                </p>{" "}
              </Button>
            </Box>
          </Form>
        </Box>
      </Box>
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <ModalMsg typeModal={modalInfo.type} textModal={modalInfo.text} />
      </Modal>
    </Box>
  );
};

export default ModificarUsuario;
