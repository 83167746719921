import React from "react";
import YouTube from "react-youtube";

class MovieClip extends React.Component {
  render() {
    const options = {
      height: "390px",
      width: "640px",
      playerVars: {
        autoplay: 1,
        controls: 1,
      },
    };
    const optionsMobile = {
      height: "220px",
      width: "320px",
      playerVars: {
        autoplay: 1,
        controls: 1,
      },
    };

    return (
      <YouTube
        videoId="OjVpWAn6IAc"
        opts={window.innerWidth < 768 ? optionsMobile : options}
        onReady={this._onReady}
        id="video"
      />
    );
  }

  _onReady(event) {
    event.target.pauseVideo();
  }
}

export default MovieClip;
