import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Form, Input, Button } from "semantic-ui-react";
import axios from "axios";
import logoBlueFooterImg from "../../assets/Logo_SoftBlue_Footer.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/ModalPopUp/Modal";
import { MainContext } from "../../helpers/MainContext";
import ModalMsg from "../../components/ModalPopUp/ModalMsg";
import GradientButton from "../../components/GradientButton";
import env from "react-dotenv";
import { encryptData } from "../../helpers/forAuth";

const IniciarSesion = () => {
  const [userData, setUserData] = useState({ email: "", password: "" });
  const { updateAuthStatus, updateUserInfo, updateUserRol } =
    useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleChange = (e, { name, value }) =>
    setUserData({ ...userData, [name]: value.trim() });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post(`https://tennis-open-league-api.vercel.app/v1/user/authenticate`, userData)
      .then((res) => {
        if (res.status === 200) {
          updateAuthStatus(true);
          let userStoredData = res.data;
          updateUserInfo(userStoredData);
          buildToken(userStoredData.cedula);
          navigate("/dashboard", { state: { userStoredData } });
        }
      })
      .catch((error) => {
        console.log(error);
        /* if (error.response.request.status === 404) {
          setShow(true);
        } */
        setShow(true);
      })
      .finally(setLoading(false));
  };

  const buildToken = async (userToken) => {
    let finalToken = encryptData(userToken);
    sessionStorage.setItem("token", finalToken);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#03438a",
        display: "flex",
        placeContent: "center",
        height: "100vh",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: {
            md: "30%",
            xs: "80%",
          },
          display: "flex",
          placeContent: "center",
          flexDirection: "column",
          border: "1px solid #ffffff",
          height: "fit-content",
          padding: "50px",
          textAlign: "center",
          boxShadow: "5px 5px 5px #03435a",
        }}
      >
        <Box sx={{ width: { md: "100%", xs: "100%" } }}>
          <img
            src={logoBlueFooterImg}
            alt="Tennis Open League"
            style={{
              width: window.innerWidth < 768 ? "50%" : "30%",
              marginBottom: "30px",
            }}
          />
        </Box>
        <Box>
          <Form onSubmit={handleSubmit} className="contact-form-inicio">
            <Form.Field
              id="form-input-control-email"
              control={Input}
              name="email"
              placeholder="Correo o Cédula"
              required
              onChange={handleChange}
            />
            <Form.Field
              id="form-input-control-password"
              control={Input}
              type="password"
              name="password"
              placeholder="Contraseña"
              onChange={handleChange}
              required
            />
            <Button
              type="submit"
              color="orange"
              className={loading ? "spinner-button" : null}
              disabled={loading}
            >
              Inicia Sesion
            </Button>
            <Link to="/recuperarcontrasena">
              <Typography
                sx={{ color: "#ffffff", marginTop: "30px !important" }}
              >
                Recuperar Contraseña
              </Typography>
            </Link>
          </Form>
        </Box>
      </Box>
      <GradientButton
        text="HOME"
        typeButton="nohover"
        extraStyles={{
          marginTop: "30px",
          background: `linear-gradient(to right, #0082e5, #0082e5)`,
          border: "none",
        }}
        onClick={() => navigate("/")}
      />
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <ModalMsg
          typeModal="error"
          textModal="Correo Electrónico y/o Clave Incorrecta"
        />
      </Modal>
    </Box>
  );
};

export default IniciarSesion;
