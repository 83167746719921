import ReactCaroussel from "react-caroussel";
import "react-caroussel/dist/index.css";
import "./slider.css";
import { Box } from "@mui/material";
import logoRS from "../../assets/Logo_RS.svg";
import logoMultiservicios from "../../assets/Logo_MULTISERVICIOS.svg";
import logoGioia from "../../assets/Logo_GIOIA.svg";
import logoAandW from "../../assets/Logo_A&W.png";

const patrocinantesList = [logoRS, logoMultiservicios, logoGioia, logoAandW];
const Card = ({ imgSrc }) => (
  <Box sx={{ width: "100px", height: "100px", display: "flex" }}>
    <img src={imgSrc} alt="patrocinantes" style={{ width: "100px" }} />
  </Box>
);

export default function Carousel() {
  return (
    <Box sx={{ width: { md: "40%", xs: "100%" }, marginBottom: "40px" }}>
      <ReactCaroussel
        autoplay
        slidesToShow={1}
        slidesToScroll={1}
        infinite={true}
      >
        {patrocinantesList.map((sponsor, key) => (
          <Card imgSrc={sponsor} key={key} />
        ))}
      </ReactCaroussel>
    </Box>
  );
}
