import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import LogoPrincipal from "../assets/logo_TO_header.png";
import { menuItemsList } from "../helpers/constants";
import SearchIcon from "@mui/icons-material/Search";
import GradientButton from "./GradientButton";
import { useNavigate } from "react-router-dom";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar() {
  const navigate = useNavigate()


  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const buttonExtraStyles =
    window.innerWidth < 768
      ? { fontSize: "0.5rem", padding: "5px 15px" }
      : null;
  return (
    <AppBar
      position="static"
      id="appbar"
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        paddingTop: "20px",
      }}
    >
      <Container maxWidth="xl" id="container">
        <Toolbar
          disableGutters
          sx={{ justifyContent: { xs: "space-between" } }}
        >
          <img
            src={LogoPrincipal}
            alt="Tennis Open League"
            style={{ height: window.innerWidth < 768 ? "40px" : "50px" }}
          />
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "space-around",
              order: { xs: "3" },
              maxWidth: { xs: "min-content", md: "auto" },
            }}
          >
            <IconButton
              size={window.innerWidth < 758 ? "small" : "large"}
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                mt: "1px",
                "& .MuiMenu-paper": { backgroundColor: "#03438a" },
              }}
            >
              {menuItemsList.map((page) => (
                <MenuItem
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ color: "#ffffff" }}
                  component="a"
                  href={`#${page}`}
                >
                  <Typography
                    sx={{ textAlign: "center", textTransform: "capitalize" }}
                  >
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "space-around",
            }}
          >
            {menuItemsList.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  textTransform: "capitalize",
                  fontSize: "0.8rem",
                }}
                component="a"
                href={`#${page}`}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Button
            sx={{
              border: "2px solid #ffffff",
              marginRight: { md: "20px", xs: 0 },
              minWidth: { md: "64px", xs: "30px" },
              width: { md: "40px", xs: "30px" },
              padding: { md: "6px 8px", xs: 0 },
            }}
          >
            <SearchIcon style={{ color: "#ffffff" }} />
          </Button>
          <Box sx={{ flexGrow: 0 }}>
            <GradientButton
              text="Inicia Sesión"
              color="orange"
              extraStyles={buttonExtraStyles}
              onClick={() => navigate("/iniciarsesion")}
            />
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
